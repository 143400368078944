import { useState } from 'react';
import { useAuth } from '@frontegg/react';
import { submitSolutionFeedback, submitDesktopFeedback } from '../services/nocodbService';
import type { FeedbackFormState } from '../types/feedback';

interface UseFeedbackSubmissionProps {
  solutionName: string;
}

interface SubmissionState {
  isSubmitting: boolean;
  error: string | null;
  submitted: boolean;
}

export const useFeedbackSubmission = ({ solutionName }: UseFeedbackSubmissionProps) => {
  const { user } = useAuth();
  const [submissionState, setSubmissionState] = useState<SubmissionState>({
    isSubmitting: false,
    error: null,
    submitted: false,
  });

  const handleSubmit = async (formState: FeedbackFormState) => {
    if (!user?.id) {
      setSubmissionState(prev => ({
        ...prev,
        error: 'User ID not found. Please ensure you are logged in.'
      }));
      return;
    }

    setSubmissionState(prev => ({ ...prev, isSubmitting: true }));

    try {
      if (solutionName === 'Desktop Application') {
        // Handle Desktop Application feedback
        await submitDesktopFeedback(user.id, {
          Applications: formState.rating,
          Knowledge: formState.rating,
          Automation: formState.rating,
          Status_Page: formState.rating,
          Search: formState.rating,
          Troubleshoot: formState.rating,
          Challenges: formState.addressesChallenges ? 'Yes' : 'No',
          Importance: formState.challengesImportance,
          Payment: formState.paymentConsideration ? 'Yes' : 'No',
          Competitors: formState.competitorDetails,
          Additional: formState.additionalFeedback,
          Call: formState.bookFollowUp ? 'Yes' : 'No',
          Client: user.email || ''
        });
      } else {
        // Handle other solutions feedback
        await submitSolutionFeedback({
          clientId: user.id,
          solutionName,
          rating: formState.rating,
          challenges: formState.addressesChallenges ? 'Yes' : 'No',
          describe: formState.challengeDetails,
          paying: formState.paymentConsideration || false,
          vendors: formState.competitorDetails,
          thoughts: formState.additionalFeedback,
          meeting: formState.bookFollowUp ? 'Yes' : 'No'
        });
      }

      setSubmissionState({
        isSubmitting: false,
        error: null,
        submitted: true
      });
    } catch (err) {
      setSubmissionState({
        isSubmitting: false,
        error: 'Failed to submit feedback. Please try again.',
        submitted: false
      });
      console.error('Error submitting feedback:', err);
    }
  };

  return {
    ...submissionState,
    handleSubmit
  };
};
