import React, { useState } from 'react';
import FeedbackForm from '../FeedbackForm';
import { Users, RefreshCw, Brain, FileText, ExternalLink } from 'lucide-react';
import VideoModal from '../VideoModal';
import PitchVideoButton from '../PitchVideoButton';

interface MasterFixDatabaseProps {
  activeSection: string;
  name: string;
}

const MasterFixDatabase: React.FC<MasterFixDatabaseProps> = ({ activeSection, name }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [showVideo, setShowVideo] = useState(false);

  const tabNames = ['Overview', 'End-User Empowerment', 'Technician Collaboration', 'Dynamic Content', 'Real-Time Solutions', 'AI-Powered Recommendations', 'Example Use Cases'];

  const renderContent = () => {
    switch (activeSection) {
      case 'Overview':
        return (
          <div className="space-y-8">
            <header className="text-center mb-12 bg-gradient-to-r from-blue-600 to-blue-800 text-white py-16 rounded-lg">
              <h1 className="text-4xl font-bold mb-4">The Actual Intelligence Engine</h1>
              <p className="text-xl mb-6">Explore the solutions that drive the fixes.</p>
              <PitchVideoButton onClick={() => setShowVideo(true)} />
            </header>

            <VideoModal 
              isOpen={showVideo}
              onClose={() => setShowVideo(false)}
              title="Master Fix Database Overview"
            />

            <div className="mb-8">
              <div className="flex justify-center flex-wrap">
                {tabNames.map((tabName, index) => (
                  <button
                    key={index}
                    className={`px-4 py-2 m-1 rounded-full ${activeTab === index ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-700'}`}
                    onClick={() => setActiveTab(index)}
                  >
                    {tabName}
                  </button>
                ))}
              </div>
            </div>

            <div className="tab-content">
              {activeTab === 0 && (
                <div className="space-y-6">
                  <p className="text-lg">FixDB is the intelligent, community-driven knowledge base behind FixFinder, designed for precision and real-time problem-solving.</p>
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                    {[
                      { icon: Users, title: 'Community-Powered Insights' },
                      { icon: Brain, title: 'AI-Enhanced Recommendations' },
                      { icon: FileText, title: 'Vendor Documentation + Real-World Workarounds' },
                      { icon: RefreshCw, title: 'Real-Time Updates from IT Professionals' }
                    ].map((feature, index) => (
                      <div key={index} className="bg-white p-4 rounded-lg shadow-md flex flex-col items-center text-center">
                        <feature.icon className="w-12 h-12 text-blue-600 mb-2" />
                        <h3 className="font-semibold">{feature.title}</h3>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        );

      case 'Prototype':
        return (
          <div className="space-y-6">
            <h2 className="text-3xl font-bold mb-6 text-center">{name} Prototype</h2>
            
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-2xl font-semibold mb-4">Prototype Overview</h3>
              <p className="mb-6">
                The Master Fix Database prototype demonstrates our innovative approach to knowledge management and problem resolution.
              </p>
              <div className="text-center">
                <a 
                  href="https://fixdb.fixfinder.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-flex items-center bg-blue-600 text-white px-8 py-3 rounded-full font-semibold hover:bg-blue-700 transition duration-300"
                >
                  <ExternalLink size={20} className="mr-2" />
                  Open Prototype
                </a>
              </div>
            </div>

            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-2xl font-semibold mb-4">Key Features</h3>
              <div className="space-y-4">
                {[
                  'AI-Enhanced Search & Recommendations',
                  'Real-Time Knowledge Updates',
                  'Community-Driven Insights'
                ].map((feature, index) => (
                  <div key={index} className="p-4 bg-gray-50 rounded-lg">
                    <h4 className="font-semibold mb-2">{feature}</h4>
                    <p>Detailed implementation of {feature.toLowerCase()}...</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        );

      case 'Feedback':
        return (
          <div className="space-y-6">
            <h2 className="text-3xl font-bold mb-6 text-center">Share Your Thoughts</h2>
            <FeedbackForm solutionName={name} />
          </div>
        );

      default:
        return <p>Select a section to view details.</p>;
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      {renderContent()}
    </div>
  );
};

export default MasterFixDatabase;