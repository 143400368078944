import React, { useState } from 'react';
import { Compass, Users, Target, ArrowRight, ChevronLeft, ChevronRight, X, Play } from 'lucide-react';
import FeedbackForm from '../FeedbackForm';
import Slider from 'react-slick';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

interface OnboardingNavigatorProps {
  activeSection: string;
  name: string;
}

const mockupImages = [
  {
    url: 'https://cdn.prod.website-files.com/6656cc98d077ac69272f468d/67203c64692c531912c95f5f_Onboarding-Navigator-Logo.jpg',
    caption: 'Onboarding Navigator Logo'
  },
  {
    url: 'https://cdn.prod.website-files.com/6656cc98d077ac69272f468d/67203c65632df41e33dd2042_Onboarding-Navigator-Main-Screen.jpg',
    caption: 'Onboarding Navigator Main Screen'
  },
  {
    url: 'https://cdn.prod.website-files.com/6656cc98d077ac69272f468d/67203c64bc304378fe715bbf_Onboarding-Navigator-Trainings.jpg',
    caption: 'Onboarding Navigator Trainings'
  }
];

const NextArrow = ({ onClick }: { onClick?: () => void }) => (
  <button
    onClick={onClick}
    className="absolute right-4 top-1/2 transform -translate-y-1/2 z-10 bg-black bg-opacity-75 p-3 rounded-full text-white hover:bg-opacity-90 transition-all"
  >
    <ChevronRight size={24} />
  </button>
);

const PrevArrow = ({ onClick }: { onClick?: () => void }) => (
  <button
    onClick={onClick}
    className="absolute left-4 top-1/2 transform -translate-y-1/2 z-10 bg-black bg-opacity-75 p-3 rounded-full text-white hover:bg-opacity-90 transition-all"
  >
    <ChevronLeft size={24} />
  </button>
);

const OnboardingNavigator: React.FC<OnboardingNavigatorProps> = ({ activeSection, name }) => {
  const [showVideo, setShowVideo] = useState(false);
  
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    dotsClass: 'slick-dots custom-dots',
  };

  const renderContent = () => {
    switch (activeSection) {
      case 'Overview':
        return (
          <div className="space-y-8">
            <header className="text-center mb-12 bg-gradient-to-r from-blue-600 to-blue-800 text-white py-16 rounded-lg">
              <h1 className="text-4xl font-bold mb-4">Onboarding Navigator</h1>
              <p className="text-xl mb-6">Revolutionizing the employee onboarding experience through intelligent automation and personalized learning paths</p>
              <button 
                onClick={() => setShowVideo(true)}
                className="bg-white text-blue-600 px-6 py-2 rounded-full font-semibold hover:bg-blue-100 transition duration-300 flex items-center mx-auto"
              >
                <Play size={20} className="mr-2" />
                Pitch Video
              </button>
            </header>

            {showVideo && (
              <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50 p-4">
                <div className="relative w-full max-w-4xl bg-black rounded-lg overflow-hidden">
                  <button
                    onClick={() => setShowVideo(false)}
                    className="absolute top-4 right-4 text-white hover:text-gray-300 z-10"
                  >
                    <X size={24} />
                  </button>
                  <div className="aspect-video bg-gray-800 flex items-center justify-center">
                    <div className="text-center text-white">
                      <Play size={48} className="mx-auto mb-4 opacity-50" />
                      <p className="text-xl">Video Coming Soon</p>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              {[
                {
                  icon: Compass,
                  title: 'Intelligent Pathways',
                  description: 'AI-driven personalized onboarding journeys based on role, experience, and learning style'
                },
                {
                  icon: Users,
                  title: 'Collaborative Learning',
                  description: 'Interactive team integration with peer mentoring and social learning features'
                },
                {
                  icon: Target,
                  title: 'Progress Tracking',
                  description: 'Real-time analytics and milestone tracking for both employees and managers'
                }
              ].map((feature, index) => (
                <div key={index} className="bg-white p-6 rounded-lg shadow-md">
                  <feature.icon className="w-8 h-8 text-blue-600 mb-4" />
                  <h4 className="text-xl font-semibold mb-2">{feature.title}</h4>
                  <p className="text-gray-600">{feature.description}</p>
                </div>
              ))}
            </div>

            <div className="bg-white p-8 rounded-lg shadow-md">
              <h2 className="text-2xl font-semibold mb-6">Key Features</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div className="space-y-4">
                  <h3 className="text-xl font-semibold text-blue-600">For New Employees</h3>
                  <ul className="space-y-2">
                    <li className="flex items-center">
                      <ArrowRight className="w-5 h-5 text-blue-500 mr-2" />
                      Personalized learning paths
                    </li>
                    <li className="flex items-center">
                      <ArrowRight className="w-5 h-5 text-blue-500 mr-2" />
                      Interactive training modules
                    </li>
                    <li className="flex items-center">
                      <ArrowRight className="w-5 h-5 text-blue-500 mr-2" />
                      Resource library access
                    </li>
                    <li className="flex items-center">
                      <ArrowRight className="w-5 h-5 text-blue-500 mr-2" />
                      Progress tracking dashboard
                    </li>
                  </ul>
                </div>
                <div className="space-y-4">
                  <h3 className="text-xl font-semibold text-blue-600">For HR & Managers</h3>
                  <ul className="space-y-2">
                    <li className="flex items-center">
                      <ArrowRight className="w-5 h-5 text-blue-500 mr-2" />
                      Automated workflow management
                    </li>
                    <li className="flex items-center">
                      <ArrowRight className="w-5 h-5 text-blue-500 mr-2" />
                      Performance analytics
                    </li>
                    <li className="flex items-center">
                      <ArrowRight className="w-5 h-5 text-blue-500 mr-2" />
                      Compliance tracking
                    </li>
                    <li className="flex items-center">
                      <ArrowRight className="w-5 h-5 text-blue-500 mr-2" />
                      Custom template builder
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="bg-blue-50 p-8 rounded-lg">
              <h2 className="text-2xl font-semibold mb-4">Why Onboarding Navigator?</h2>
              <p className="text-gray-700 mb-6">
                Onboarding Navigator transforms the traditional onboarding process into an engaging, efficient, and measurable journey. 
                By combining AI-driven personalization with collaborative learning features, we ensure every new employee receives 
                the perfect blend of standardized training and role-specific guidance.
              </p>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <div className="bg-white p-4 rounded-lg">
                  <h3 className="font-semibold text-blue-600 mb-2">50% Faster Integration</h3>
                  <p className="text-sm text-gray-600">Reduce time-to-productivity with structured learning paths</p>
                </div>
                <div className="bg-white p-4 rounded-lg">
                  <h3 className="font-semibold text-blue-600 mb-2">90% Completion Rate</h3>
                  <p className="text-sm text-gray-600">Higher engagement through interactive content</p>
                </div>
                <div className="bg-white p-4 rounded-lg">
                  <h3 className="font-semibold text-blue-600 mb-2">35% Cost Reduction</h3>
                  <p className="text-sm text-gray-600">Lower training and administrative costs</p>
                </div>
              </div>
            </div>
          </div>
        );

      case 'Mockup':
        return (
          <div className="space-y-6">
            <h2 className="text-3xl font-bold mb-6 text-center">Onboarding Navigator Mockup</h2>
            
            <div className="max-w-6xl mx-auto relative">
              <Slider {...settings}>
                {mockupImages.map((image, index) => (
                  <div key={index} className="outline-none p-4">
                    <Zoom>
                      <img
                        src={image.url}
                        alt={image.caption}
                        className="w-full h-auto object-contain rounded-lg"
                        style={{ maxHeight: '80vh' }}
                      />
                    </Zoom>
                    <p className="text-center mt-4 text-gray-600">{image.caption}</p>
                  </div>
                ))}
              </Slider>
            </div>

            <div className="bg-white p-6 rounded-lg shadow-md mt-8">
              <h3 className="text-2xl font-semibold mb-4">Design Overview</h3>
              <p className="text-gray-600">
                The Onboarding Navigator features a clean, intuitive interface designed to streamline the onboarding process.
                With clear navigation and visual progress indicators, both managers and new employees can easily track and
                complete onboarding tasks.
              </p>
            </div>
          </div>
        );

      case 'Feedback':
        return (
          <div className="space-y-6">
            <h2 className="text-3xl font-bold mb-6 text-center">Share Your Thoughts</h2>
            <FeedbackForm solutionName={name} />
          </div>
        );

      default:
        return <p>Select a section to view details.</p>;
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      {renderContent()}
    </div>
  );
};

export default OnboardingNavigator;
