import React, { useState } from 'react';
import { AlertTriangle, Activity, Clock, Bell, ArrowRight } from 'lucide-react';
import FeedbackForm from '../FeedbackForm';
import VideoModal from '../VideoModal';
import PitchVideoButton from '../PitchVideoButton';

interface OutageHubProps {
  activeSection: string;
  name: string;
}

const OutageHub: React.FC<OutageHubProps> = ({ activeSection, name }) => {
  const [showVideo, setShowVideo] = useState(false);

  const renderContent = () => {
    switch (activeSection) {
      case 'Overview':
        return (
          <div className="space-y-8">
            <header className="text-center mb-12 bg-gradient-to-r from-blue-600 to-blue-800 text-white py-16 rounded-lg">
              <h1 className="text-4xl font-bold mb-4">OutageHub - Incident Management Platform</h1>
              <p className="text-xl mb-6">Streamline outage management and incident response</p>
              <PitchVideoButton onClick={() => setShowVideo(true)} />
            </header>

            <VideoModal 
              isOpen={showVideo}
              onClose={() => setShowVideo(false)}
              title="OutageHub Overview"
            />

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
              {[
                {
                  icon: AlertTriangle,
                  title: 'Early Detection',
                  description: 'Proactive monitoring and alerts'
                },
                {
                  icon: Activity,
                  title: 'Real-time Updates',
                  description: 'Live status and incident tracking'
                },
                {
                  icon: Clock,
                  title: 'Response Time',
                  description: 'Faster incident resolution'
                },
                {
                  icon: Bell,
                  title: 'Smart Notifications',
                  description: 'Targeted alerts and updates'
                }
              ].map((feature, index) => (
                <div key={index} className="bg-white p-6 rounded-lg shadow-md">
                  <feature.icon className="w-8 h-8 text-blue-600 mb-4" />
                  <h4 className="text-xl font-semibold mb-2">{feature.title}</h4>
                  <p className="text-gray-600">{feature.description}</p>
                </div>
              ))}
            </div>

            <div className="bg-white p-8 rounded-lg shadow-md">
              <h2 className="text-2xl font-semibold mb-6">Platform Benefits</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div className="space-y-4">
                  <h3 className="text-xl font-semibold text-blue-600">For IT Teams</h3>
                  <ul className="space-y-2">
                    <li className="flex items-center">
                      <ArrowRight className="w-5 h-5 text-blue-500 mr-2" />
                      Faster incident response
                    </li>
                    <li className="flex items-center">
                      <ArrowRight className="w-5 h-5 text-blue-500 mr-2" />
                      Improved coordination
                    </li>
                    <li className="flex items-center">
                      <ArrowRight className="w-5 h-5 text-blue-500 mr-2" />
                      Better documentation
                    </li>
                  </ul>
                </div>
                <div className="space-y-4">
                  <h3 className="text-xl font-semibold text-blue-600">For Business</h3>
                  <ul className="space-y-2">
                    <li className="flex items-center">
                      <ArrowRight className="w-5 h-5 text-blue-500 mr-2" />
                      Minimized downtime
                    </li>
                    <li className="flex items-center">
                      <ArrowRight className="w-5 h-5 text-blue-500 mr-2" />
                      Cost reduction
                    </li>
                    <li className="flex items-center">
                      <ArrowRight className="w-5 h-5 text-blue-500 mr-2" />
                      Better communication
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        );

      case 'Business Case':
        return (
          <div className="space-y-6">
            <h2 className="text-3xl font-bold mb-6 text-center">OutageHub Business Case</h2>
            
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-2xl font-semibold mb-4">Market Analysis</h3>
              <div className="space-y-4">
                {[
                  {
                    title: 'Problem Statement',
                    description: 'Current incident management solutions lack integration and real-time capabilities'
                  },
                  {
                    title: 'Market Opportunity',
                    description: 'Growing demand for unified incident management platforms'
                  },
                  {
                    title: 'Target Market',
                    description: 'IT service providers and enterprise organizations'
                  }
                ].map((section, index) => (
                  <div key={index} className="p-4 bg-gray-50 rounded-lg">
                    <h4 className="font-semibold mb-2">{section.title}</h4>
                    <p>{section.description}</p>
                  </div>
                ))}
              </div>
            </div>

            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-2xl font-semibold mb-4">Financial Impact</h3>
              <div className="space-y-4">
                {[
                  {
                    metric: 'Average Incident Cost Reduction',
                    value: '35%'
                  },
                  {
                    metric: 'Mean Time to Resolution Improvement',
                    value: '45%'
                  },
                  {
                    metric: 'Annual Cost Savings',
                    value: '$150,000+'
                  }
                ].map((item, index) => (
                  <div key={index} className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
                    <span className="font-semibold">{item.metric}</span>
                    <span className="text-blue-600 font-bold">{item.value}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        );

      case 'Feedback':
        return (
          <div className="space-y-6">
            <h2 className="text-3xl font-bold mb-6 text-center">Share Your Thoughts</h2>
            <FeedbackForm solutionName={name} />
          </div>
        );

      default:
        return <p>Select a section to view details.</p>;
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      {renderContent()}
    </div>
  );
};

export default OutageHub;