import React from 'react'
import { useAuth, useLoginWithRedirect } from '@frontegg/react'
import { Lock } from 'lucide-react'

const Login: React.FC = () => {
  const { isAuthenticated } = useAuth()
  const loginWithRedirect = useLoginWithRedirect()

  if (isAuthenticated) {
    return <div>You are already logged in.</div>
  }

  const handleLogin = () => {
    console.log('Initiating login process')
    loginWithRedirect()
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 via-gray-800 to-gray-900 flex items-center justify-center p-4">
      <div className="w-full max-w-md bg-white/5 backdrop-blur-lg rounded-2xl p-8 shadow-2xl">
        <div className="flex flex-col items-center space-y-6">
          {/* Logo */}
          <img 
            src="https://cdn.prod.website-files.com/6656cc98d077ac69272f468d/666c3d6f5a0ea57d121a1846_Logo.webp" 
            alt="FixFinder Logo" 
            className="w-[244px] h-[53px] animate-fade-in"
          />
          
          {/* Title */}
          <h2 className="text-3xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-emerald-400">
            Product Research Portal
          </h2>
          
          {/* Description */}
          <p className="text-gray-300 text-center max-w-sm">
            Access our innovative solutions, prototypes, and product demonstrations.
          </p>
          
          {/* Login Button */}
          <button
            onClick={handleLogin}
            className="group relative w-full flex items-center justify-center px-6 py-3 bg-gradient-to-r from-blue-500 to-emerald-500 text-white text-sm font-semibold rounded-lg overflow-hidden transition-all duration-300 hover:from-blue-600 hover:to-emerald-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-900"
          >
            <div className="absolute inset-0 w-3 bg-gradient-to-r from-white/0 via-white/20 to-white/0 skew-x-[-45deg] group-hover:animate-shimmer" />
            <Lock className="w-4 h-4 mr-2" />
            Single Sign-On
          </button>
        </div>
      </div>
    </div>
  )
}

export default Login