import React, { useState } from 'react'
import EnhancedFigmaViewer from '../EnhancedFigmaViewer'
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import { Clock, Activity, Users, Database, Ticket, DollarSign, RefreshCw, X } from 'lucide-react'

const Dashboard: React.FC = () => {
  const [activeTab, setActiveTab] = useState<number | null>(null)

  const tabContent = [
    {
      title: 'Time Savings',
      icon: Clock,
      sections: [
        { key: 'timeSavingsOverview', title: 'Overview', content: 'Visualize cumulative time saved by leveraging automated workflows and self-service solutions.' },
        { key: 'timeSavingsImpact', title: 'Impact', content: 'Demonstrate how FixFinder reduces the burden of repetitive tasks for IT teams.' },
      ],
    },
    {
      title: 'System Health',
      icon: Activity,
      sections: [
        { key: 'systemHealthUptime', title: 'Uptime & Incidents', content: 'Track uptime and incident rates in real-time to assess the reliability of the platform.' },
        { key: 'systemHealthPerformance', title: 'Performance Indicators', content: 'Use gauge charts and color-coded indicators to monitor performance at a glance.' },
      ],
    },
    {
      title: 'User Engagement',
      icon: Users,
      sections: [
        { key: 'userEngagementMetrics', title: 'Engagement Metrics', content: 'Analyze user interactions with the platform, including session duration and frequency of visits.' },
        { key: 'userEngagementHeatmaps', title: 'Heatmaps', content: 'Use heatmaps to identify high-engagement periods and optimize the platform for increased usage.' },
      ],
    },
    {
      title: 'FixDB Utilization',
      icon: Database,
      sections: [
        { key: 'fixdbBreakdown', title: 'Fixes Breakdown', content: 'Display the number of available fixes, segmented by categories or severity levels.' },
        { key: 'fixdbPopular', title: 'Popular Solutions', content: 'Track the popularity of specific solutions to ensure the knowledge base remains relevant and effective.' },
      ],
    },
    {
      title: 'Ticket Insights',
      icon: Ticket,
      sections: [
        { key: 'ticketTrends', title: 'Ticket Trends', content: 'Monitor ticket volumes and trends over time, categorized for easy analysis.' },
        { key: 'ticketPredictive', title: 'Predictive Analytics', content: 'Leverage predictive analytics to forecast future ticket volumes, supporting proactive resource planning.' },
      ],
    },
    {
      title: 'Cost Savings',
      icon: DollarSign,
      sections: [
        { key: 'costSavingsEscalation', title: 'Escalation Reduction', content: 'Visualize reductions in ticket escalations and associated cost savings.' },
        { key: 'costSavingsEfficiency', title: 'Operational Efficiency', content: "Showcase how FixFinder's solutions drive operational efficiency by preventing unnecessary escalations." },
      ],
    },
    {
      title: 'New Fixes & Updates',
      icon: RefreshCw,
      sections: [
        { key: 'updatesFrequency', title: 'Update Frequency', content: 'Display the frequency of new fixes added to the knowledge base, segmented by release or version.' },
        { key: 'updatesImprovement', title: 'Continuous Improvement', content: "Highlight the platform's responsiveness to evolving needs through continuous updates and improvements." },
      ],
    },
  ]

  return (
    <div className="flex flex-col min-h-screen">
      <header className="text-center mb-12 bg-gradient-to-r from-blue-600 to-blue-800 text-white py-16 rounded-lg max-w-4xl mx-auto">
        <h1 className="text-3xl md:text-4xl font-bold mb-4">The Future of FixFinder Dashboards</h1>
        <p className="text-lg md:text-xl max-w-3xl mx-auto px-4">
          This is a glimpse of where FixFinder is headed, offering powerful insights into system performance, 
          user engagement, and the value of our platform.
        </p>
      </header>

      <div className="flex flex-grow p-4 md:p-6 max-w-7xl mx-auto space-x-4">
        {/* Sidebar for Tabs */}
        <div className="w-1/4 sticky top-16 bg-gray-100 rounded-lg p-4 shadow-md">
          <h2 className="text-lg font-semibold mb-4 text-center">Metrics Navigation</h2>
          <div className="flex flex-col gap-2">
            {tabContent.map((tab, index) => (
              <button
                key={index}
                className={`px-4 py-3 rounded-lg flex items-center text-sm transition-all 
                  ${activeTab === index ? 'bg-blue-500 text-white shadow-md' : 'bg-white text-gray-700 hover:bg-gray-200'}`}
                onClick={() => setActiveTab(index)}
              >
                {React.createElement(tab.icon, { className: "mr-2", size: 20 })}
                {tab.title}
              </button>
            ))}
          </div>
        </div>

        {/* Main Content Area */}
        <div className="w-3/4">
          {/* Figma Viewer */}
          <div className="mb-8">
            <Zoom>
              <EnhancedFigmaViewer fileLabel="dashboard" />
            </Zoom>
          </div>

          {/* Flyout Panel for Tab Content */}
          {activeTab !== null && (
            <div className="fixed right-0 top-0 h-full w-1/3 bg-white shadow-lg p-6 overflow-y-auto z-20">
              <button
                onClick={() => setActiveTab(null)}
                className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
              >
                <X size={24} />
              </button>
              <h3 className="text-xl font-semibold mb-4 flex items-center">
                {React.createElement(tabContent[activeTab].icon, { className: "mr-2", size: 24 })}
                {tabContent[activeTab].title}
              </h3>
              <div className="grid gap-4">
                {tabContent[activeTab].sections.map((section) => (
                  <div key={section.key} className="bg-gray-50 p-4 rounded-lg">
                    <h4 className="font-medium mb-2">{section.title}</h4>
                    <p className="text-gray-600 text-sm">{section.content}</p>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Dashboard