export const getStatusColor = (status: string) => {
  switch (status) {
    case 'completed':
      return 'bg-green-200 text-green-800';
    case 'in-progress':
      return 'bg-yellow-200 text-yellow-800';
    case 'beta':
      return 'bg-blue-200 text-blue-800';
    case 'waiting-feedback':
      return 'bg-purple-200 text-purple-800';
    default:
      return 'bg-gray-200 text-gray-800';
  }
};

export const getStatusEmoji = (status: string) => {
  switch (status) {
    case 'completed':
      return '✅';
    case 'in-progress':
      return '🔄';
    case 'beta':
      return '🧪';
    case 'waiting-feedback':
      return '📝';
    default:
      return '';
  }
};