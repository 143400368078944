import React, { useState } from 'react';
import { HelpCircle, GitBranch, Database, Zap, ArrowRight, Monitor, Server, Phone, Laptop, ExternalLink } from 'lucide-react';
import FeedbackForm from '../FeedbackForm';
import VideoModal from '../VideoModal';
import PitchVideoButton from '../PitchVideoButton';

interface ExpertPathProps {
  activeSection: string;
  name: string;
}

const ExpertPath: React.FC<ExpertPathProps> = ({ activeSection, name }) => {
  const [showVideo, setShowVideo] = useState(false);

  const renderContent = () => {
    switch (activeSection) {
      case 'Overview':
        return (
          <div className="space-y-8">
            <header className="text-center mb-12 bg-gradient-to-r from-blue-600 to-blue-800 text-white py-16 rounded-lg">
              <h1 className="text-4xl font-bold mb-4">Expert Path</h1>
              <p className="text-xl mb-6">Advanced troubleshooting assistant for IT professionals</p>
              <PitchVideoButton onClick={() => setShowVideo(true)} />
            </header>

            <VideoModal 
              isOpen={showVideo}
              onClose={() => setShowVideo(false)}
              title="Expert Path Overview"
            />

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
              {[
                {
                  icon: GitBranch,
                  title: 'Decision Trees',
                  description: 'Interactive troubleshooting flows'
                },
                {
                  icon: Database,
                  title: 'Resource Hub',
                  description: 'Centralized knowledge access'
                },
                {
                  icon: HelpCircle,
                  title: 'Smart Guidance',
                  description: 'AI-powered assistance'
                },
                {
                  icon: Zap,
                  title: 'Quick Resolution',
                  description: 'Streamlined problem-solving'
                }
              ].map((feature, index) => (
                <div key={index} className="bg-white p-6 rounded-lg shadow-md">
                  <feature.icon className="w-8 h-8 text-blue-600 mb-4" />
                  <h4 className="text-xl font-semibold mb-2">{feature.title}</h4>
                  <p className="text-gray-600">{feature.description}</p>
                </div>
              ))}
            </div>

            <div className="bg-white p-8 rounded-lg shadow-md">
              <h2 className="text-2xl font-semibold mb-6">Device Coverage</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div className="space-y-4">
                  <h3 className="text-xl font-semibold text-blue-600">Network & Infrastructure</h3>
                  <ul className="space-y-2">
                    <li className="flex items-center">
                      <Monitor className="w-5 h-5 text-blue-500 mr-2" />
                      Cisco switches and routers
                    </li>
                    <li className="flex items-center">
                      <Server className="w-5 h-5 text-blue-500 mr-2" />
                      Dell and HP servers
                    </li>
                    <li className="flex items-center">
                      <Phone className="w-5 h-5 text-blue-500 mr-2" />
                      VoIP systems
                    </li>
                  </ul>
                </div>
                <div className="space-y-4">
                  <h3 className="text-xl font-semibold text-blue-600">Endpoint Devices</h3>
                  <ul className="space-y-2">
                    <li className="flex items-center">
                      <Laptop className="w-5 h-5 text-blue-500 mr-2" />
                      Dell laptops and workstations
                    </li>
                    <li className="flex items-center">
                      <Monitor className="w-5 h-5 text-blue-500 mr-2" />
                      HP and Lenovo devices
                    </li>
                    <li className="flex items-center">
                      <Server className="w-5 h-5 text-blue-500 mr-2" />
                      Network attached storage
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="bg-white p-8 rounded-lg shadow-md">
              <h2 className="text-2xl font-semibold mb-6">Troubleshooting Flow</h2>
              <div className="space-y-6">
                {[
                  {
                    title: 'Issue Identification',
                    description: 'Select device type and symptoms through interactive 3D models'
                  },
                  {
                    title: 'Guided Diagnostics',
                    description: 'Follow AI-powered scripts with targeted questions and solutions'
                  },
                  {
                    title: 'Resource Access',
                    description: 'View relevant documentation, known issues, and recent tickets'
                  },
                  {
                    title: 'Resolution Path',
                    description: 'Get clear steps for resolution or proper escalation paths'
                  }
                ].map((step, index) => (
                  <div key={index} className="flex items-start p-4 bg-gray-50 rounded-lg">
                    <ArrowRight className="w-5 h-5 text-blue-500 mr-2 mt-1" />
                    <div>
                      <h3 className="font-semibold mb-2">{step.title}</h3>
                      <p className="text-gray-600">{step.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        );

      case 'Prototype':
        return (
          <div className="space-y-6">
            <h2 className="text-3xl font-bold mb-6 text-center">{name} Prototype</h2>
            
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-2xl font-semibold mb-4">Prototype Overview</h3>
              <p className="mb-6">
                The Expert Path prototype demonstrates our innovative approach to guided troubleshooting and problem resolution,
                showcasing how IT professionals can leverage AI-powered assistance to resolve issues more efficiently.
              </p>
              <div className="text-center">
                <a 
                  href="https://expertpath.fixfinder.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-flex items-center bg-blue-600 text-white px-8 py-3 rounded-full font-semibold hover:bg-blue-700 transition duration-300"
                >
                  <ExternalLink size={20} className="mr-2" />
                  Open Prototype
                </a>
              </div>
            </div>

            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-2xl font-semibold mb-4">Key Features</h3>
              <div className="space-y-4">
                {[
                  'Interactive Device Selection',
                  'AI-Powered Diagnostic Trees',
                  'Real-Time Knowledge Base Integration',
                  'Smart Resolution Pathways',
                  'Performance Analytics Dashboard'
                ].map((feature, index) => (
                  <div key={index} className="p-4 bg-gray-50 rounded-lg">
                    <h4 className="font-semibold mb-2">{feature}</h4>
                    <p>Experience the power of {feature.toLowerCase()} in streamlining your troubleshooting process.</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        );

      case 'Feedback':
        return (
          <div className="space-y-6">
            <h2 className="text-3xl font-bold mb-6 text-center">Share Your Thoughts</h2>
            <FeedbackForm solutionName={name} />
          </div>
        );

      default:
        return <p>Select a section to view details.</p>;
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      {renderContent()}
    </div>
  );
};

export default ExpertPath;