import React from 'react';
import { X, Play } from 'lucide-react';

interface VideoModalProps {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
}

const VideoModal: React.FC<VideoModalProps> = ({ isOpen, onClose, title }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50 p-4">
      <div className="relative w-full max-w-4xl bg-black rounded-lg overflow-hidden">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-white hover:text-gray-300 z-10"
        >
          <X size={24} />
        </button>
        <div className="aspect-video bg-gray-800 flex items-center justify-center">
          <div className="text-center text-white">
            <Play size={48} className="mx-auto mb-4 opacity-50" />
            <p className="text-xl">{title || 'Video Coming Soon'}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoModal;