import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '@frontegg/react';

const OAuthCallback: React.FC = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    console.log('OAuthCallback triggered');

    const params = new URLSearchParams(location.search);
    const code = params.get('code');
    const nonce = params.get('nonce');

    console.log('Params:', { code, nonce });

    if (code && nonce) {
      console.log('OAuth Callback - Code:', code);
      console.log('OAuth Callback - Nonce:', nonce);

      // Redirect to dashboard after successful authentication
      navigate('/dashboard', { replace: true });
    } else {
      console.error('OAuth callback failed: Missing code or nonce');
      navigate('/login'); // Redirect to login if OAuth fails
    }
  }, [location, navigate, user]);

  return <div>Processing authentication...</div>;
};

export default OAuthCallback;