import React, { useState } from 'react'
import { Share2, Key, Globe, Cloud, Shield, Users, Clock, Database, AlertTriangle, Play, X, Lock, Building2, LayoutDashboard, Shield as ShieldIcon, Settings, Key as KeyIcon } from 'lucide-react'
import ImageCarousel from '../ImageCarousel'

const Portal: React.FC = () => {
  const [activeSection, setActiveSection] = useState(0)
  const [showVideo, setShowVideo] = useState(false)

  const carouselImages = [
    { url: 'https://cdn.prod.website-files.com/6656cc98d077ac69272f468d/670af3eecd8231b48dd1efaa_Adaptive-MFA_Images.png.webp', caption: 'Adaptive MFA' },
    { url: 'https://cdn.prod.website-files.com/6656cc98d077ac69272f468d/670af3ee4ac577806f8eabbd_Security_Suite_Architectural_Diagram.png.webp', caption: 'Security Suite Architecture' },
    { url: 'https://cdn.prod.website-files.com/6656cc98d077ac69272f468d/670af3eebd10c72aae2c9c83_Step-up_Image.png.webp', caption: 'Step-up Authentication' },
    { url: 'https://cdn.prod.website-files.com/6656cc98d077ac69272f468d/670af3ee01de0ce1383286a6_Security-Logs_Image.png.webp', caption: 'Security Logs' },
    { url: 'https://cdn.prod.website-files.com/6656cc98d077ac69272f468d/670af3ef0f6a6cfc3ac89c0a_Security-events_Image.png.webp', caption: 'Security Events' },
    { url: 'https://cdn.prod.website-files.com/6656cc98d077ac69272f468d/670af3ef0f6a6cfc3ac89c0d_Security_Image.png.webp', caption: 'Security Overview' },
    { url: 'https://cdn.prod.website-files.com/6656cc98d077ac69272f468d/670af3ef0f6a6cfc3ac89c10_Custom-Security-Rules_Image.png.webp', caption: 'Custom Security Rules' }
  ]

  const architectureCategories = [
    {
      title: 'Authentication',
      icon: Lock,
      items: [
        { name: 'Login box', description: 'Customizable authentication interface' },
        { name: 'Social Logins', description: 'Integration with major social providers' },
        { name: 'Passwordless', description: 'Modern authentication methods' },
        { name: 'Multitenancy', description: 'Support for multiple organizations' }
      ]
    },
    {
      title: 'Authorization & Entitlements',
      icon: KeyIcon,
      items: [
        { name: 'Entitlements', description: 'Fine-grained access control' },
        { name: 'RBAC, FGA, ABAC', description: 'Flexible authorization models' },
        { name: 'Feature Flags', description: 'Dynamic feature management' }
      ]
    },
    {
      title: 'Enterprise Readiness',
      icon: Building2,
      items: [
        { name: 'SSO', description: 'Single Sign-On capabilities' },
        { name: 'SCIM', description: 'Identity provisioning' },
        { name: 'MFA', description: 'Multi-factor authentication' },
        { name: 'Audit Logs', description: 'Comprehensive activity tracking' }
      ]
    },
    {
      title: 'Enterprise Scale',
      icon: Building2,
      items: [
        { name: 'Hierarchies', description: 'Flexible organizational structures' },
        { name: 'Multi-Apps', description: 'Support for multiple applications' }
      ]
    },
    {
      title: 'Management & Analytics',
      icon: LayoutDashboard,
      items: [
        { name: 'Environments', description: 'Development and production management' },
        { name: 'User Management', description: 'Comprehensive user control' },
        { name: 'Account Management', description: 'Account lifecycle handling' },
        { name: 'Monitoring', description: 'System health and performance tracking' },
        { name: 'Emails', description: 'Email management and templates' },
        { name: 'Prehooks', description: 'Pre-execution triggers' },
        { name: 'Webhooks', description: 'External integrations' }
      ]
    },
    {
      title: 'Admin Portal',
      icon: Settings,
      items: [
        { name: 'Personal and Tenant Management', description: 'User and organization control' },
        { name: 'Team Management', description: 'Team organization and roles' },
        { name: 'Groups', description: 'User grouping and permissions' },
        { name: 'Security', description: 'Security settings and policies' },
        { name: 'Webhooks', description: 'Integration management' },
        { name: 'API Tokens', description: 'Access token management' }
      ]
    },
    {
      title: 'Security Suite',
      icon: ShieldIcon,
      items: [
        { name: 'Security Events', description: 'Real-time security monitoring' },
        { name: 'Security Logs', description: 'Detailed activity tracking' },
        { name: 'Custom Security Rules', description: 'Flexible security policies' },
        { name: 'Adaptive MFA', description: 'Context-aware authentication' },
        { name: 'Security Admin Portal', description: 'Security management interface' },
        { name: 'Step up', description: 'Progressive authentication' }
      ]
    }
  ]

  const sections = [
    {
      title: 'Platform Integration',
      icon: Share2,
      description: 'Seamless integration with all major platforms and authentication providers.',
      content: (
        <div className="space-y-4">
          <p className="text-gray-600">
            Our platform provides out-of-the-box integrations with Google, Microsoft, Apple, GitHub, Slack, and LinkedIn, 
            allowing users to log in effortlessly. No more remembering passwords—authentication is quick, simple, and secure.
          </p>
          <div className="grid grid-cols-2 gap-4">
            {[
              { name: 'Social Logins', icon: Share2 },
              { name: 'Passwordless Auth', icon: Key },
              { name: 'Multi-App Federation', icon: Globe },
              { name: 'Self-Service SSO', icon: Cloud }
            ].map((feature, index) => (
              <div key={index} className="bg-gray-100 p-4 rounded-lg flex items-center">
                <feature.icon className="mr-2 text-blue-600" size={20} />
                <p className="font-semibold text-sm">{feature.name}</p>
              </div>
            ))}
          </div>
        </div>
      )
    },
    {
      title: 'Enterprise Security',
      icon: Shield,
      description: 'Advanced security features to protect your data and users.',
      content: (
        <div className="space-y-4">
          <p className="text-gray-600">
            Our security features go beyond the basics to provide multi-factor authentication (MFA), bot detection, 
            and proactive security recommendations.
          </p>
          <div className="grid grid-cols-2 gap-4">
            {[
              'MFA (Key, SMS, TOTP)',
              'Bot Detection',
              'Identity API',
              'Security Recommendations',
              'Brute Force Detection',
              'Breached Password Detection',
              'Suspicious IP Detection',
              'Stale User Management'
            ].map((feature, index) => (
              <div key={index} className="bg-gray-100 p-4 rounded-lg flex items-center">
                <Shield className="mr-2 text-blue-600" size={18} />
                <p className="font-semibold text-sm">{feature}</p>
              </div>
            ))}
          </div>
        </div>
      )
    },
    {
      title: 'Self-Service Tools',
      icon: Users,
      description: 'Empower users with self-service capabilities.',
      content: (
        <div className="space-y-4">
          <p className="text-gray-600">
            From hierarchical management to user roles, our platform enables self-service that reduces IT overhead 
            and puts control back into the hands of your users.
          </p>
          <div className="grid grid-cols-2 gap-4">
            {[
              'Self-Service Hierarchies',
              'User Profile Portal',
              'Subscription Management',
              'Custom Roles',
              'User Groups'
            ].map((feature, index) => (
              <div key={index} className="bg-gray-100 p-4 rounded-lg flex items-center">
                <Users className="mr-2 text-green-600" size={18} />
                <p className="font-semibold text-sm">{feature}</p>
              </div>
            ))}
          </div>
        </div>
      )
    },
    {
      title: 'Future Updates',
      icon: Clock,
      description: 'Upcoming features and platform enhancements.',
      content: (
        <div className="space-y-4">
          <p className="text-gray-600">
            Stay ahead of the curve with our upcoming features, including enhanced admin tools, more login options, 
            and better tenant management.
          </p>
          <div className="grid grid-cols-2 gap-4">
            {[
              'SCIM Self-Service',
              'SSO Self-Service',
              'Subscription Management',
              'Role Mapping from IDP',
              'Custom Login Page'
            ].map((feature, index) => (
              <div key={index} className="bg-gray-100 p-4 rounded-lg flex items-center">
                <Clock className="mr-2 text-purple-600" size={18} />
                <p className="font-semibold text-sm">{feature}</p>
              </div>
            ))}
          </div>
        </div>
      )
    },
    {
      title: 'Access Control',
      icon: Database,
      description: 'Fine-grained control over access and permissions.',
      content: (
        <div className="space-y-4">
          <p className="text-gray-600">
            From tenant-level tokens to user-level restrictions, our platform gives you complete control over session policies, 
            login behavior, and identity mapping.
          </p>
          <div className="grid grid-cols-2 gap-4">
            {[
              'Session Management',
              'Tenant Level Tokens',
              'User Level Tokens',
              'Role Mapping',
              'Token Restrictions'
            ].map((feature, index) => (
              <div key={index} className="bg-gray-100 p-4 rounded-lg flex items-center">
                <Database className="mr-2 text-indigo-600" size={18} />
                <p className="font-semibold text-sm">{feature}</p>
              </div>
            ))}
          </div>
        </div>
      )
    },
    {
      title: 'Security Analytics',
      icon: AlertTriangle,
      description: 'Advanced threat detection and monitoring.',
      content: (
        <div className="space-y-4">
          <p className="text-gray-600">
            Our platform includes advanced threat protection features like suspicious IP detection, impossible travel monitoring, 
            and audit logs to help you stay on top of security risks.
          </p>
          <div className="grid grid-cols-2 gap-4">
            {[
              'Audit Logging',
              'Suspicious IP Detection',
              'Impossible Travel',
              'Accounts at Risk'
            ].map((feature, index) => (
              <div key={index} className="bg-gray-100 p-4 rounded-lg flex items-center">
                <AlertTriangle className="mr-2 text-yellow-600" size={18} />
                <p className="font-semibold text-sm">{feature}</p>
              </div>
            ))}
          </div>
        </div>
      )
    }
  ]

  const scrollLeft = () => {
    const container = document.getElementById('architecture-scroll')
    if (container) {
      container.scrollBy({ left: -300, behavior: 'smooth' })
    }
  }

  const scrollRight = () => {
    const container = document.getElementById('architecture-scroll')
    if (container) {
      container.scrollBy({ left: 300, behavior: 'smooth' })
    }
  }

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Header */}
      <header className="text-center mb-8 bg-gradient-to-r from-blue-600 to-blue-800 text-white py-16">
        <div className="max-w-4xl mx-auto px-4">
          <h1 className="text-3xl md:text-4xl font-bold mb-4">FixFinder Platform Portal</h1>
          <p className="text-lg md:text-xl">
            Enterprise-grade security and seamless authentication across all FixFinder applications
          </p>
        </div>
      </header>

      {/* Video Section */}
      <div className="max-w-2xl mx-auto mb-8 px-4">
        <div className="w-full max-w-2xl mx-auto aspect-video bg-gray-200 flex items-center justify-center mb-4 rounded-lg">
          <button
            onClick={() => setShowVideo(true)}
            className="flex flex-col items-center text-gray-600 hover:text-gray-800 transition-colors"
          >
            <Play size={48} className="mb-2" />
            <p className="text-xl">Video Coming Soon</p>
          </button>
        </div>
      </div>

      {/* Carousel Section */}
      <div className="max-w-4xl mx-auto mb-12 px-4">
        <h2 className="text-xl md:text-2xl font-semibold mb-3">Integrated Security Features</h2>
        <p className="text-sm md:text-base text-gray-600 mb-4">
          The FixFinder platform portal provides a comprehensive set of security features to protect your data and ensure seamless access.
        </p>
        <ImageCarousel images={carouselImages} />
      </div>

      {/* Architecture Overview */}
      <div className="max-w-7xl mx-auto px-4 mb-12">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-2xl font-semibold">Platform Architecture</h2>
          <div className="flex gap-2">
            <button
              onClick={scrollLeft}
              className="p-2 rounded-full bg-white shadow-md hover:bg-gray-50"
            >
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
              </svg>
            </button>
            <button
              onClick={scrollRight}
              className="p-2 rounded-full bg-white shadow-md hover:bg-gray-50"
            >
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
              </svg>
            </button>
          </div>
        </div>
        <div 
          id="architecture-scroll"
          className="flex overflow-x-auto gap-6 pb-4 hide-scrollbar"
          style={{
            scrollbarWidth: 'none',
            msOverflowStyle: 'none',
            WebkitOverflowScrolling: 'touch'
          }}
        >
          {architectureCategories.map((category, index) => (
            <div key={index} className="bg-white p-6 rounded-lg shadow-md min-w-[300px] flex-shrink-0">
              <div className="flex items-center mb-4">
                {React.createElement(category.icon, { size: 24, className: "text-blue-600 mr-2" })}
                <h3 className="text-xl font-semibold">{category.title}</h3>
              </div>
              <div className="space-y-3">
                {category.items.map((item, itemIndex) => (
                  <div key={itemIndex} className="border-b border-gray-100 last:border-0 pb-2 last:pb-0">
                    <h4 className="font-medium text-gray-900">{item.name}</h4>
                    <p className="text-sm text-gray-600">{item.description}</p>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Main Content */}
      <div className="max-w-7xl mx-auto px-4 pb-12">
        <div className="flex gap-8">
          {/* Sidebar Navigation */}
          <div className="w-64 flex-shrink-0">
            <div className="sticky top-4 bg-white rounded-lg shadow-sm p-4">
              <h2 className="text-lg font-semibold mb-4">Features</h2>
              <nav className="space-y-1">
                {sections.map((section, index) => (
                  <button
                    key={index}
                    onClick={() => setActiveSection(index)}
                    className={`w-full flex items-center px-4 py-2 text-sm rounded-lg transition-colors ${
                      activeSection === index
                        ? 'bg-blue-50 text-blue-700 font-medium'
                        : 'text-gray-600 hover:bg-gray-50'
                    }`}
                  >
                    {React.createElement(section.icon, {
                      size: 18,
                      className: `mr-3 ${activeSection === index ? 'text-blue-700' : 'text-gray-400'}`
                    })}
                    {section.title}
                  </button>
                ))}
              </nav>
            </div>
          </div>

          {/* Content Area */}
          <div className="flex-1 bg-white rounded-lg shadow-sm p-6">
            <h2 className="text-2xl font-semibold mb-2 flex items-center">
              {React.createElement(sections[activeSection].icon, { size: 24, className: "mr-3 text-blue-600" })}
              {sections[activeSection].title}
            </h2>
            <p className="text-gray-600 mb-6">{sections[activeSection].description}</p>
            {sections[activeSection].content}
          </div>
        </div>
      </div>

      {/* Video Modal */}
      {showVideo && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50 p-4">
          <div className="relative w-full max-w-4xl bg-black rounded-lg overflow-hidden">
            <button
              onClick={() => setShowVideo(false)}
              className="absolute top-4 right-4 text-white hover:text-gray-300 z-10"
            >
              <X size={24} />
            </button>
            <div className="aspect-video bg-gray-800 flex items-center justify-center">
              <div className="text-center text-white">
                <Play size={48} className="mx-auto mb-4 opacity-50" />
                <p className="text-xl">Video Coming Soon</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Portal
