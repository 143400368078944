import React from 'react';
import { Play } from 'lucide-react';

interface PitchVideoButtonProps {
  onClick: () => void;
}

const PitchVideoButton: React.FC<PitchVideoButtonProps> = ({ onClick }) => {
  return (
    <button 
      onClick={onClick}
      className="bg-white text-blue-600 px-6 py-2 rounded-full font-semibold hover:bg-blue-100 transition duration-300 flex items-center mx-auto"
    >
      <Play size={20} className="mr-2" />
      Pitch Video
    </button>
  );
};

export default PitchVideoButton;