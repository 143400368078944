import React from 'react'
import { X } from 'lucide-react'
import { Tool } from './types'

interface ToolBrowserProps {
  isOpen: boolean
  onClose: () => void
  tools: Tool[]
  selectedTools: Set<string>
  onToolSelect: (toolName: string) => void
  onAddSelected: () => void
}

const ToolBrowser: React.FC<ToolBrowserProps> = ({
  isOpen,
  onClose,
  tools,
  selectedTools,
  onToolSelect,
  onAddSelected
}) => {
  if (!isOpen) return null

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-6 rounded-lg max-w-lg w-full">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-xl font-semibold">Select Tools</h3>
          <button 
            onClick={onClose}
            className="p-1 hover:bg-gray-100 rounded-full transition-colors duration-200"
          >
            <X className="text-gray-500 hover:text-gray-700" />
          </button>
        </div>
        <div className="max-h-80 overflow-y-auto">
          {tools.map(tool => (
            <div key={tool.id} className="flex items-center p-2 hover:bg-gray-50 rounded-md">
              <input
                type="checkbox"
                id={`tool-${tool.id}`}
                checked={selectedTools.has(tool.name)}
                onChange={() => onToolSelect(tool.name)}
                className="mr-3 h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
              />
              <label htmlFor={`tool-${tool.id}`} className="flex-grow cursor-pointer">
                {tool.name}
              </label>
            </div>
          ))}
        </div>
        <button
          onClick={onAddSelected}
          className="mt-6 w-full px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors duration-200"
        >
          Add Selected Tools
        </button>
      </div>
    </div>
  )
}

export default ToolBrowser