import React, { useState, useEffect } from 'react';
import { useAuth } from '@frontegg/react';
import ToolSearch from '../integrations/ToolSearch';
import ToolBrowser from '../integrations/ToolBrowser';
import FeedbackList from '../integrations/FeedbackList';
import CustomIntegrationModal from '../integrations/CustomIntegrationModal';
import { Tool, UserFeedback } from '../integrations/types';
import { getIntegrationFeedback, submitIntegrationFeedback } from '../../services/nocodbService';

const initialTools: Tool[] = [
  { id: '1', name: 'AirSlate' },
  { id: '2', name: 'Atera' },
  { id: '3', name: 'Automate.io' },
  { id: '4', name: 'Automation Anywhere' },
  { id: '5', name: 'Automox' },
  { id: '6', name: 'Auvik' },
  { id: '7', name: 'Better Uptime' },
  { id: '8', name: 'Bitdefender GravityZone' },
  { id: '9', name: 'Bloomfire' },
  { id: '10', name: 'BMS by Kaseya' },
  { id: '11', name: 'Box' },
  { id: '12', name: 'Chef Automate' },
  { id: '13', name: 'Coda' },
  { id: '14', name: 'Confluence' },
  { id: '15', name: 'ConnectWise Automate' },
  { id: '16', name: 'ConnectWise Manage' },
  { id: '17', name: 'Continuum' },
  { id: '18', name: 'CrowdStrike' },
  { id: '19', name: 'Datto Autotask PSA' },
  { id: '20', name: 'Datto Commerce' },
  { id: '21', name: 'Datto File Protection' },
  { id: '22', name: 'Datto RMM' },
  { id: '23', name: 'Datto SaaS Protection' },
  { id: '24', name: 'Datto Workplace' },
  { id: '25', name: 'Document360' },
  { id: '26', name: 'DropBox' },
  { id: '27', name: 'Egnyte' },
  { id: '28', name: 'Freshdesk' },
  { id: '29', name: 'FreshService' },
  { id: '30', name: 'Freshstatus' },
  { id: '31', name: 'Graphus' },
  { id: '32', name: 'Guru' },
  { id: '33', name: 'HaloITSM' },
  { id: '34', name: 'HaloPSA' },
  { id: '35', name: 'Help Scout' },
  { id: '36', name: 'HelpDocs' },
  { id: '37', name: 'Helpjuice' },
  { id: '38', name: 'IT Glue' },
  { id: '39', name: 'ITBoost' },
  { id: '40', name: 'Intune' },
  { id: '41', name: 'Integromat' },
  { id: '42', name: 'ITarian' },
  { id: '43', name: 'Jira Service Management' },
  { id: '44', name: 'JumpCloud' },
  { id: '45', name: 'Kaseya BMS' },
  { id: '46', name: 'Kaseya VSA' },
  { id: '47', name: 'Kissflow' },
  { id: '48', name: 'LogicMonitor' },
  { id: '49', name: 'ManageEngine Desktop Central' },
  { id: '50', name: 'ManageEngine Patch Manager Plus' },
  { id: '51', name: 'ManageEngine ServiceDesk Plus' },
  { id: '52', name: 'Microsoft Power Apps' },
  { id: '53', name: 'MSP360 RMM' },
  { id: '54', name: 'n8n' },
  { id: '55', name: 'N-able N-central' },
  { id: '56', name: 'Naverisk' },
  { id: '57', name: 'Ninite Pro' },
  { id: '58', name: 'NinjaRMM' },
  { id: '59', name: 'Notion' },
  { id: '60', name: 'Nuclino' },
  { id: '61', name: 'Okta' },
  { id: '62', name: 'OneLogin' },
  { id: '63', name: 'Onna' },
  { id: '64', name: 'PDQ Deploy' },
  { id: '65', name: 'Pabbly Connect' },
  { id: '66', name: 'Passportal' },
  { id: '67', name: 'Power Automate' },
  { id: '68', name: 'ProProfs Knowledge Base' },
  { id: '69', name: 'Pulseway' },
  { id: '70', name: 'Puppet Enterprise' },
  { id: '71', name: 'RapidFire Tools' },
  { id: '72', name: 'Resolve Systems' },
  { id: '73', name: 'ServiceNow' },
  { id: '74', name: 'ServiceNow ITSM' },
  { id: '75', name: 'SharePoint' },
  { id: '76', name: 'Slab' },
  { id: '77', name: 'SolarWinds Network Performance Monitor (NPM)' },
  { id: '78', name: 'SolarWinds RMM' },
  { id: '79', name: 'Spanning Backup' },
  { id: '80', name: 'Status.io' },
  { id: '81', name: 'Statuspage by Atlassian' },
  { id: '82', name: 'Statuspal' },
  { id: '83', name: 'Syncro MSP' },
  { id: '84', name: 'SysAid' },
  { id: '85', name: 'Tettra' },
  { id: '86', name: 'Tigerpaw' },
  { id: '87', name: 'Tonkean' },
  { id: '88', name: 'Tray.io' },
  { id: '89', name: 'UiPath' },
  { id: '90', name: 'Unitrends Cloud Backup' },
  { id: '91', name: 'Veeam' },
  { id: '92', name: 'Workato' },
  { id: '93', name: 'Workfusion' },
  { id: '94', name: 'Zapier' },
  { id: '95', name: 'Zendesk Guide' },
  { id: '96', name: 'Zendesk Support' },
  { id: '97', name: 'Zoho Desk' },
  { id: '98', name: 'Zoho Wiki' }
].sort((a, b) => a.name.localeCompare(b.name));

const Integrations: React.FC = () => {
  const { user } = useAuth();
  const [searchTerm, setSearchTerm] = useState('');
  const [userFeedback, setUserFeedback] = useState<UserFeedback[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCustomModalOpen, setIsCustomModalOpen] = useState(false);
  const [selectedToolsInModal, setSelectedToolsInModal] = useState<Set<string>>(new Set());
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const loadExistingIntegrations = async () => {
      if (user?.email) {
        try {
          const existingFeedback = await getIntegrationFeedback(user.email);
          if (existingFeedback.length > 0) {
            const storedIntegrations: UserFeedback[] = [];

            existingFeedback.forEach(record => {
              // Create an array of integration field keys
              const integrationKeys = Array.from({ length: 10 }, (_, i) => `Integration${i + 1}` as keyof typeof record);
              
              integrationKeys.forEach(key => {
                const value = record[key];
                if (value && typeof value === 'string') {
                  const [name, description] = value.split(': ');
                  storedIntegrations.push({
                    toolName: name,
                    customDescription: description || '',
                    isExpanded: false,
                    isSaved: true,
                    timestamp: Date.now()
                  });
                }
              });
            });

            setUserFeedback(storedIntegrations);
          }
        } catch (err) {
          console.error('Error loading existing integrations:', err);
          setError('Failed to load existing integrations');
        }
      }
    };

    loadExistingIntegrations();
  }, [user?.email]);

  const handleToggleExpand = (toolName: string) => {
    setUserFeedback(prevFeedback => 
      prevFeedback.map(feedback => ({
        ...feedback,
        isExpanded: feedback.toolName === toolName ? !feedback.isExpanded : feedback.isExpanded
      }))
    );
  };

  const handleFeedbackChange = (toolName: string, description: string) => {
    setUserFeedback(prevFeedback =>
      prevFeedback.map(feedback =>
        feedback.toolName === toolName ? { ...feedback, customDescription: description } : feedback
      )
    );
  };

  const handleSaveFeedback = async (toolName: string) => {
    if (!user?.email) {
      setError('Please log in to save feedback');
      return;
    }

    try {
      const feedbackToSave = userFeedback
        .filter(feedback => feedback.toolName === toolName || feedback.isSaved)
        .map(feedback => ({
          name: feedback.toolName,
          description: feedback.customDescription
        }));

      await submitIntegrationFeedback(user.email, feedbackToSave);

      setUserFeedback(prevFeedback =>
        prevFeedback.map(feedback =>
          feedback.toolName === toolName ? { ...feedback, isSaved: true } : feedback
        )
      );
      setError(null);
    } catch (err) {
      console.error('Error saving feedback:', err);
      setError('Failed to save feedback');
    }
  };

  const handleEditFeedback = (toolName: string) => {
    setUserFeedback(prevFeedback =>
      prevFeedback.map(feedback =>
        feedback.toolName === toolName ? { ...feedback, isSaved: false } : feedback
      )
    );
  };

  const handleRemoveTool = (toolName: string) => {
    setUserFeedback(prevFeedback => prevFeedback.filter(feedback => feedback.toolName !== toolName));
  };

  const handleToolSelect = (toolName: string) => {
    const updatedSelectedTools = new Set(selectedToolsInModal);
    if (updatedSelectedTools.has(toolName)) {
      updatedSelectedTools.delete(toolName);
    } else {
      updatedSelectedTools.add(toolName);
    }
    setSelectedToolsInModal(updatedSelectedTools);
  };

  const handleAddSelectedTools = () => {
    const selectedToolsArray = Array.from(selectedToolsInModal);
    const newFeedback = selectedToolsArray
      .filter(toolName => !userFeedback.some(feedback => feedback.toolName === toolName))
      .map(toolName => ({
        toolName,
        customDescription: '',
        isExpanded: true,
        isSaved: false,
        timestamp: Date.now()
      }));
    setUserFeedback([...userFeedback, ...newFeedback]);
    setSelectedToolsInModal(new Set());
    setIsModalOpen(false);
  };

  const handleCustomToolAdd = (tool: Tool) => {
    if (!userFeedback.some(feedback => feedback.toolName === tool.name)) {
      setUserFeedback([
        ...userFeedback,
        {
          toolName: tool.name,
          customDescription: '',
          isExpanded: true,
          isSaved: false,
          timestamp: Date.now()
        }
      ]);
    }
  };

  const filteredTools = initialTools.filter(tool =>
    tool.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleToolAdd = (tool: Tool) => {
    if (!userFeedback.some(feedback => feedback.toolName === tool.name)) {
      setUserFeedback([
        ...userFeedback,
        { 
          toolName: tool.name, 
          customDescription: '', 
          isExpanded: true, 
          isSaved: false,
          timestamp: Date.now()
        }
      ]);
    }
    setSearchTerm('');
  };

  // Sort feedback to show unsaved/new items first, then by timestamp
  const sortedFeedback = [...userFeedback].sort((a, b) => {
    if (a.isSaved !== b.isSaved) {
      return a.isSaved ? 1 : -1;
    }
    return b.timestamp - a.timestamp;
  });

  return (
    <div className="p-6 max-w-6xl mx-auto">
      <header className="text-center mb-12 bg-gradient-to-r from-blue-600 to-blue-800 text-white py-16 rounded-lg">
        <h1 className="text-3xl md:text-4xl font-bold mb-4">Integrations</h1>
        <p className="text-lg md:text-xl max-w-3xl mx-auto px-4">
          FixFinder is a vendor-agnostic platform designed to connect rapidly with new tools and services.
          Our goal is to understand what tools you're using now and how you want to integrate them.
        </p>
      </header>

      <div className="bg-white rounded-lg shadow-md p-6 mb-8">
        {error && (
          <div className="mb-4 bg-red-100 border-l-4 border-red-500 text-red-700 p-4" role="alert">
            <p className="font-bold">Error</p>
            <p>{error}</p>
          </div>
        )}

        <ToolSearch
          searchTerm={searchTerm}
          onSearchChange={setSearchTerm}
          onBrowseClick={() => setIsModalOpen(true)}
          onCustomClick={() => setIsCustomModalOpen(true)}
          filteredTools={filteredTools}
          onToolAdd={handleToolAdd}
        />

        <ToolBrowser
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          tools={initialTools}
          selectedTools={selectedToolsInModal}
          onToolSelect={handleToolSelect}
          onAddSelected={handleAddSelectedTools}
        />

        <CustomIntegrationModal
          isOpen={isCustomModalOpen}
          onClose={() => setIsCustomModalOpen(false)}
          onAdd={handleCustomToolAdd}
          existingTools={initialTools}
        />

        <FeedbackList
          feedback={sortedFeedback}
          onToggleExpand={handleToggleExpand}
          onFeedbackChange={handleFeedbackChange}
          onSaveFeedback={handleSaveFeedback}
          onEditFeedback={handleEditFeedback}
          onRemoveTool={handleRemoveTool}
          saveLabel="Save"
          editLabel="Edit"
          title="Your Integrations"
        />
      </div>
    </div>
  );
};

export default Integrations;
