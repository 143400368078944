import React from 'react';
import { ChevronDown, ChevronUp, Link2, X } from 'lucide-react';
import { UserFeedback } from './types';

interface FeedbackListProps {
  feedback: UserFeedback[];
  onToggleExpand: (toolName: string) => void;
  onFeedbackChange: (toolName: string, description: string) => void;
  onSaveFeedback: (toolName: string) => Promise<void> | void;
  onEditFeedback: (toolName: string) => void;
  onRemoveTool: (toolName: string) => void;
  saveLabel?: string;
  editLabel?: string;
  title?: string;
}

const FeedbackList: React.FC<FeedbackListProps> = ({
  feedback,
  onToggleExpand,
  onFeedbackChange,
  onSaveFeedback,
  onEditFeedback,
  onRemoveTool,
  saveLabel = 'Save Feedback',
  editLabel = 'Edit Feedback',
  title = 'Your Feedback'
}) => {
  if (feedback.length === 0) return null;

  const isCustomTool = (toolName: string) => {
    return toolName.startsWith('custom-');
  };

  return (
    <div className="mb-8">
      <h3 className="text-xl font-semibold mb-6">{title}:</h3>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {feedback.map((item) => (
          <div
            key={item.toolName}
            className={`rounded-lg shadow-md transition-all duration-200 overflow-hidden ${
              !item.isSaved ? 'ring-2 ring-blue-500 bg-blue-50' : 'bg-white'
            }`}
          >
            <div className="p-4 border-b bg-gray-50">
              <div className="flex justify-between items-center">
                <div className="flex items-center space-x-2">
                  <Link2 size={18} className="text-blue-600" />
                  <h4 className="font-medium text-lg">{item.toolName}</h4>
                </div>
                <div className="flex items-center space-x-2">
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      onToggleExpand(item.toolName);
                    }}
                    className="p-1 hover:bg-gray-200 rounded-full transition-colors duration-200"
                  >
                    {item.isExpanded ? (
                      <ChevronUp size={20} className="text-gray-600" />
                    ) : (
                      <ChevronDown size={20} className="text-gray-600" />
                    )}
                  </button>
                  {(!item.isSaved || isCustomTool(item.toolName)) && (
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        onRemoveTool(item.toolName);
                      }}
                      className="p-1 hover:bg-red-100 rounded-full transition-colors duration-200"
                      title="Remove integration"
                    >
                      <X size={20} className="text-red-500" />
                    </button>
                  )}
                </div>
              </div>
            </div>
            
            {item.isExpanded && (
              <div className="p-4" onClick={(e) => e.stopPropagation()}>
                <textarea
                  placeholder="What would you do with this tool if it integrated to the FixFinder platform?"
                  value={item.customDescription}
                  onChange={(e) => onFeedbackChange(item.toolName, e.target.value)}
                  className="w-full p-3 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent bg-white"
                  disabled={item.isSaved}
                  rows={4}
                />
                <div className="flex justify-end mt-3">
                  {!item.isSaved ? (
                    <button
                      onClick={() => onSaveFeedback(item.toolName)}
                      disabled={!item.customDescription.trim()}
                      className={`px-4 py-2 rounded-md flex items-center space-x-2 ${
                        item.customDescription.trim()
                          ? 'bg-blue-600 text-white hover:bg-blue-700'
                          : 'bg-gray-300 text-gray-500 cursor-not-allowed'
                      } transition-colors duration-200`}
                    >
                      {saveLabel}
                    </button>
                  ) : (
                    <button
                      onClick={() => onEditFeedback(item.toolName)}
                      className="px-4 py-2 bg-gray-600 text-white rounded-md hover:bg-gray-700 transition-colors duration-200 flex items-center space-x-2"
                    >
                      {editLabel}
                    </button>
                  )}
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FeedbackList;
