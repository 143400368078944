import React from 'react';
import { Search } from 'lucide-react';
import { Tool } from './types';

interface ToolSearchProps {
  searchTerm: string;
  onSearchChange: (value: string) => void;
  onBrowseClick: () => void;
  onCustomClick: () => void;
  filteredTools: Tool[];
  onToolAdd: (tool: Tool) => void;
}

const ToolSearch: React.FC<ToolSearchProps> = ({
  searchTerm,
  onSearchChange,
  onBrowseClick,
  onCustomClick,
  filteredTools,
  onToolAdd
}) => {
  return (
    <>
      <div className="flex justify-between mb-8 gap-4">
        <div className="relative flex-1">
          <input
            type="text"
            placeholder="Add your integrations and tell us how you'd use them with FixFinder..."
            value={searchTerm}
            onChange={(e) => onSearchChange(e.target.value)}
            className="w-full p-2 pl-10 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          />
          <Search className="absolute left-3 top-2.5 text-gray-400" size={20} />
        </div>
        <div className="flex gap-2">
          <button 
            onClick={onBrowseClick} 
            className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors duration-200"
          >
            Browse
          </button>
          <button 
            onClick={onCustomClick}
            className="px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700 transition-colors duration-200"
          >
            Custom
          </button>
        </div>
      </div>

      {searchTerm && (
        <div className="mb-4 border rounded-md shadow-sm overflow-hidden">
          <ul className="max-h-64 overflow-y-auto">
            {filteredTools.map(tool => (
              <li 
                key={tool.id} 
                className="p-3 hover:bg-gray-50 cursor-pointer border-b last:border-b-0 transition-colors duration-200" 
                onClick={() => onToolAdd(tool)}
              >
                {tool.name}
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};

export default ToolSearch;