import React, { useState } from 'react'
import EnhancedFigmaViewer from '../EnhancedFigmaViewer'
import 'react-medium-image-zoom/dist/styles.css'

const Welcome: React.FC = () => {
  const [activeTab, setActiveTab] = useState('story')

  const tabContent = {
    story: "FixFinder was born out of the challenges we faced as an IT support business dealing with high ticket volumes, especially from clients with remote employees. Despite having all the usual resources—training videos, articles, phone/chat/ticket support, and knowledge bases—the real issue was accessibility. Clients found calling or opening a ticket easier than searching through distant portals.",
    culture: "At FixFinder, we foster a culture of innovation, collaboration, and continuous improvement. Our team is dedicated to creating solutions that make a real difference in how businesses handle IT support and knowledge management.",
    approach: "Our approach is centered on user experience and accessibility. We believe that the key to effective IT support is making information and solutions readily available and easy to find. FixFinder is designed to bridge the gap between traditional support channels and the needs of modern, distributed workforces."
  }

  return (
    <div className="p-6 flex flex-col items-center">
      <header className="text-center mb-12 bg-gradient-to-r from-blue-600 to-blue-800 text-white py-16 rounded-lg max-w-4xl mx-auto w-full">
        <h1 className="text-3xl md:text-4xl font-bold mb-4">Welcome to FixFinder Research Portal</h1>
        <p className="text-lg md:text-xl max-w-3xl mx-auto px-4">
          Explore our innovative solutions, prototypes, and product demonstrations. We value your feedback 
          and insights as we continue to develop cutting-edge products to meet your needs.
        </p>
      </header>

      <div className="w-full max-w-2xl mx-auto mb-12 bg-white p-6 rounded-lg shadow-md relative">
        <h2 className="text-2xl font-semibold mb-4">Founder/CEO Introduction</h2>

        <div className="absolute top-32 left-1/2 transform -translate-x-1/2 z-10">
          <a href="https://www.linkedin.com/in/infostruction/" target="_blank" rel="noopener noreferrer">
            <img 
              src="https://cdn.prod.website-files.com/6656cc98d077ac69272f468d/665d9868176c47295a70e1dc_img%201%2080px.webp" 
              alt="Matthew Infostruction LinkedIn Profile" 
              className="w-24 h-24 rounded-full border-4 border-white shadow-lg object-cover"
            />
          </a>
        </div>

        <div className="aspect-video bg-gray-200 rounded-lg flex items-center justify-center mb-4 mt-16">
          <div className="text-center">
            <p className="text-xl text-gray-600 mb-2">Video Placeholder</p>
            <p className="text-gray-500">Coming soon</p>
          </div>
        </div>
        <p className="text-gray-600">
          Watch our Founder/CEO discuss the inspiration behind FixFinder and our commitment to revolutionizing IT support.
        </p>
      </div>

      <div className="w-full max-w-3xl mx-auto mb-12">
        <div className="flex rounded-t-lg overflow-hidden">
          {['story', 'culture', 'approach'].map((tab) => (
            <button
              key={tab}
              className={`flex-1 py-2 px-4 text-center ${
                activeTab === tab
                  ? 'bg-indigo-600 text-white'
                  : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
              }`}
              onClick={() => setActiveTab(tab)}
            >
              {tab.charAt(0).toUpperCase() + tab.slice(1)}
            </button>
          ))}
        </div>
        <div className="bg-white p-6 rounded-b-lg shadow-md">
          <p className="text-gray-700">
            {tabContent[activeTab as keyof typeof tabContent]}
          </p>
        </div>
      </div>

      <div className="w-full max-w-4xl mx-auto bg-white p-6 rounded-lg shadow-md">
        <h2 className="text-2xl font-semibold mb-4">FixFinder Overview</h2>
        <div className="relative">
          <EnhancedFigmaViewer fileLabel="flyer" />
        </div>
      </div>
    </div>
  )
}

export default Welcome