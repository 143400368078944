import React, { useState } from 'react';
import Slider from 'react-slick';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { ChevronLeft, ChevronRight, X } from 'lucide-react';

interface ImageCarouselProps {
  images: { url: string; caption?: string }[];
}

const ImageCarousel: React.FC<ImageCarouselProps> = ({ images }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isFullScreen, setIsFullScreen] = useState(false);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: 'ondemand' as const,
    beforeChange: (_: number, next: number) => setCurrentSlide(next),
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  const handleFullScreenToggle = () => {
    setIsFullScreen(!isFullScreen);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Escape') {
      setIsFullScreen(false);
    }
  };

  return (
    <div
      className={`relative ${isFullScreen ? 'fixed inset-0 z-50 bg-black' : 'max-w-3xl mx-auto'}`}
      onKeyDown={handleKeyDown}
      tabIndex={0}
    >
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index} className="outline-none">
            <Zoom>
              <img
                src={image.url}
                alt={`Slide ${index + 1}`}
                className="w-full h-auto object-contain max-h-[60vh]"
                loading="lazy"
              />
            </Zoom>
            {image.caption && (
              <p className="text-center mt-2 text-sm text-gray-600">{image.caption}</p>
            )}
          </div>
        ))}
      </Slider>
      <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 text-white bg-black bg-opacity-50 px-2 py-1 rounded">
        {currentSlide + 1} / {images.length}
      </div>
      <button
        onClick={handleFullScreenToggle}
        className="absolute top-4 right-4 text-white bg-black bg-opacity-50 p-2 rounded-full"
      >
        {isFullScreen ? <X size={24} /> : <span className="text-2xl">⛶</span>}
      </button>
    </div>
  );
};

const NextArrow: React.FC<{ onClick?: () => void }> = ({ onClick }) => (
  <button
    onClick={onClick}
    className="absolute right-2 top-1/2 transform -translate-y-1/2 z-10 bg-black bg-opacity-50 p-1 rounded-full text-white"
  >
    <ChevronRight size={20} />
  </button>
);

const PrevArrow: React.FC<{ onClick?: () => void }> = ({ onClick }) => (
  <button
    onClick={onClick}
    className="absolute left-2 top-1/2 transform -translate-y-1/2 z-10 bg-black bg-opacity-50 p-1 rounded-full text-white"
  >
    <ChevronLeft size={20} />
  </button>
);

export default ImageCarousel;