import React, { useState } from 'react';
import { X } from 'lucide-react';
import { Tool } from './types';

interface CustomIntegrationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onAdd: (tool: Tool) => void;
  existingTools: Tool[];
}

const CustomIntegrationModal: React.FC<CustomIntegrationModalProps> = ({
  isOpen,
  onClose,
  onAdd,
  existingTools
}) => {
  const [name, setName] = useState('');
  const [error, setError] = useState('');

  if (!isOpen) return null;

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    
    // Validation
    if (!name.trim()) {
      setError('Please enter an integration name');
      return;
    }

    // Check if tool already exists
    const exists = existingTools.some(
      tool => tool.name.toLowerCase() === name.trim().toLowerCase()
    );

    if (exists) {
      setError('This integration already exists in our list');
      return;
    }

    // Create new tool with generated ID
    const newTool: Tool = {
      id: `custom-${Date.now()}`,
      name: name.trim()
    };

    onAdd(newTool);
    setName('');
    setError('');
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-6 rounded-lg max-w-md w-full">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-xl font-semibold">Add Custom Integration</h3>
          <button 
            onClick={onClose}
            className="p-1 hover:bg-gray-100 rounded-full transition-colors duration-200"
          >
            <X className="text-gray-500 hover:text-gray-700" />
          </button>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="integration-name" className="block text-sm font-medium text-gray-700 mb-2">
              Integration Name
            </label>
            <input
              id="integration-name"
              type="text"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
                setError('');
              }}
              className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              placeholder="Enter the name of your integration"
            />
            {error && <p className="mt-2 text-sm text-red-600">{error}</p>}
          </div>

          <div className="flex justify-end gap-2">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-gray-700 hover:bg-gray-100 rounded-md transition-colors duration-200"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors duration-200"
            >
              Add Integration
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CustomIntegrationModal;