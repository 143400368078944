import React, { useState } from 'react';
import { BarChart3, Bot, Zap, Shield, ArrowRight } from 'lucide-react';
import FeedbackForm from '../FeedbackForm';
import VideoModal from '../VideoModal';
import PitchVideoButton from '../PitchVideoButton';

interface RoboticProcessAutomationProps {
  activeSection: string;
  name: string;
}

const RoboticProcessAutomation: React.FC<RoboticProcessAutomationProps> = ({ activeSection, name }) => {
  const [showVideo, setShowVideo] = useState(false);

  const renderContent = () => {
    switch (activeSection) {
      case 'Overview':
        return (
          <div className="space-y-8">
            <header className="text-center mb-12 bg-gradient-to-r from-blue-600 to-blue-800 text-white py-16 rounded-lg">
              <h1 className="text-4xl font-bold mb-4">Robotic Process Automation</h1>
              <p className="text-xl mb-6">Automate repetitive tasks and streamline operations</p>
              <PitchVideoButton onClick={() => setShowVideo(true)} />
            </header>

            <VideoModal 
              isOpen={showVideo}
              onClose={() => setShowVideo(false)}
              title="Robotic Process Automation Overview"
            />

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
              {[
                {
                  icon: Bot,
                  title: 'Intelligent Bots',
                  description: 'AI-powered automation'
                },
                {
                  icon: Zap,
                  title: 'Fast Execution',
                  description: 'Rapid task completion'
                },
                {
                  icon: Shield,
                  title: 'Secure Operations',
                  description: 'Enterprise-grade security'
                },
                {
                  icon: BarChart3,
                  title: 'Analytics',
                  description: 'Performance tracking'
                }
              ].map((feature, index) => (
                <div key={index} className="bg-white p-6 rounded-lg shadow-md">
                  <feature.icon className="w-8 h-8 text-blue-600 mb-4" />
                  <h4 className="text-xl font-semibold mb-2">{feature.title}</h4>
                  <p className="text-gray-600">{feature.description}</p>
                </div>
              ))}
            </div>

            <div className="bg-white p-8 rounded-lg shadow-md">
              <h2 className="text-2xl font-semibold mb-6">Automation Benefits</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div className="space-y-4">
                  <h3 className="text-xl font-semibold text-blue-600">For Operations</h3>
                  <ul className="space-y-2">
                    <li className="flex items-center">
                      <ArrowRight className="w-5 h-5 text-blue-500 mr-2" />
                      Increased efficiency
                    </li>
                    <li className="flex items-center">
                      <ArrowRight className="w-5 h-5 text-blue-500 mr-2" />
                      Error reduction
                    </li>
                    <li className="flex items-center">
                      <ArrowRight className="w-5 h-5 text-blue-500 mr-2" />
                      24/7 operation
                    </li>
                  </ul>
                </div>
                <div className="space-y-4">
                  <h3 className="text-xl font-semibold text-blue-600">For Business</h3>
                  <ul className="space-y-2">
                    <li className="flex items-center">
                      <ArrowRight className="w-5 h-5 text-blue-500 mr-2" />
                      Cost savings
                    </li>
                    <li className="flex items-center">
                      <ArrowRight className="w-5 h-5 text-blue-500 mr-2" />
                      Faster processing
                    </li>
                    <li className="flex items-center">
                      <ArrowRight className="w-5 h-5 text-blue-500 mr-2" />
                      Better compliance
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        );

      case 'Business Case':
        return (
          <div className="space-y-6">
            <h2 className="text-3xl font-bold mb-6 text-center">RPA Business Case</h2>
            
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-2xl font-semibold mb-4">ROI Analysis</h3>
              <div className="space-y-4">
                {[
                  {
                    title: 'Cost Savings',
                    description: 'Average 65% reduction in operational costs'
                  },
                  {
                    title: 'Productivity Gains',
                    description: '40% increase in process efficiency'
                  },
                  {
                    title: 'Error Reduction',
                    description: '99.9% accuracy in automated processes'
                  }
                ].map((section, index) => (
                  <div key={index} className="p-4 bg-gray-50 rounded-lg">
                    <h4 className="font-semibold mb-2">{section.title}</h4>
                    <p>{section.description}</p>
                  </div>
                ))}
              </div>
            </div>

            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-2xl font-semibold mb-4">Implementation Strategy</h3>
              <div className="space-y-4">
                {[
                  {
                    phase: 'Phase 1: Process Analysis',
                    duration: '2-3 weeks',
                    activities: 'Process mapping and optimization opportunities'
                  },
                  {
                    phase: 'Phase 2: Bot Development',
                    duration: '4-6 weeks',
                    activities: 'Bot creation and testing'
                  },
                  {
                    phase: 'Phase 3: Deployment',
                    duration: '2-3 weeks',
                    activities: 'Production rollout and monitoring'
                  }
                ].map((phase, index) => (
                  <div key={index} className="p-4 bg-gray-50 rounded-lg">
                    <h4 className="font-semibold">{phase.phase}</h4>
                    <p className="text-sm text-gray-600">{phase.duration}</p>
                    <p>{phase.activities}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        );

      case 'Feedback':
        return (
          <div className="space-y-6">
            <h2 className="text-3xl font-bold mb-6 text-center">Share Your Thoughts</h2>
            <FeedbackForm solutionName={name} />
          </div>
        );

      default:
        return <p>Select a section to view details.</p>;
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      {renderContent()}
    </div>
  );
};

export default RoboticProcessAutomation;