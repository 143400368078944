import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { FronteggProvider } from '@frontegg/react'
import { UserProvider } from './contexts/UserContext'
import App from './App.tsx'
import './index.css'

const contextOptions = {
  baseUrl: 'https://app-6yutcyqk6ant.us.frontegg.com',
  clientId: 'af20fef1-c350-4b74-ac85-af1538eb4c38',
  appId: '50714f08-fbf2-4ce9-9b6d-d53047540165',
};

createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <BrowserRouter>
      <FronteggProvider contextOptions={contextOptions} hostedLoginBox={true}>
        <UserProvider>
          <App />
        </UserProvider>
      </FronteggProvider>
    </BrowserRouter>
  </React.StrictMode>,
)