import React, { useState } from 'react';
import { BarChart3, Bot, Zap, Shield, ArrowRight, Play, X } from 'lucide-react';
import FeedbackForm from '../FeedbackForm';

interface AutomationServiceProps {
  activeSection: string;
  name: string;
}

const AutomationService: React.FC<AutomationServiceProps> = ({ activeSection, name }) => {
  const [showVideo, setShowVideo] = useState(false);

  const renderContent = () => {
    switch (activeSection) {
      case 'Overview':
        return (
          <div className="space-y-8">
            <header className="text-center mb-12 bg-gradient-to-r from-blue-600 to-blue-800 text-white py-16 rounded-lg">
              <h1 className="text-4xl font-bold mb-4">Automation-as-a-Service</h1>
              <p className="text-xl mb-6">Enterprise automation solutions for modern businesses</p>
              <button 
                onClick={() => setShowVideo(true)}
                className="bg-white text-blue-600 px-6 py-2 rounded-full font-semibold hover:bg-blue-100 transition duration-300 flex items-center mx-auto"
              >
                <Play size={20} className="mr-2" />
                Pitch Video
              </button>
            </header>

            {showVideo && (
              <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50 p-4">
                <div className="relative w-full max-w-4xl bg-black rounded-lg overflow-hidden">
                  <button
                    onClick={() => setShowVideo(false)}
                    className="absolute top-4 right-4 text-white hover:text-gray-300 z-10"
                  >
                    <X size={24} />
                  </button>
                  <div className="aspect-video bg-gray-800 flex items-center justify-center">
                    <div className="text-center text-white">
                      <Play size={48} className="mx-auto mb-4 opacity-50" />
                      <p className="text-xl">Video Coming Soon</p>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* Rest of the existing Overview content remains unchanged */}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
              {[
                {
                  icon: Bot,
                  title: 'Intelligent Bots',
                  description: 'AI-powered automation'
                },
                {
                  icon: Zap,
                  title: 'Fast Integration',
                  description: 'Quick deployment'
                },
                {
                  icon: Shield,
                  title: 'Secure Operations',
                  description: 'Enterprise-grade security'
                },
                {
                  icon: BarChart3,
                  title: 'Analytics',
                  description: 'Performance tracking'
                }
              ].map((feature, index) => (
                <div key={index} className="bg-white p-6 rounded-lg shadow-md">
                  <feature.icon className="w-8 h-8 text-blue-600 mb-4" />
                  <h4 className="text-xl font-semibold mb-2">{feature.title}</h4>
                  <p className="text-gray-600">{feature.description}</p>
                </div>
              ))}
            </div>

            <div className="bg-white p-8 rounded-lg shadow-md">
              <h2 className="text-2xl font-semibold mb-6">Service Benefits</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div className="space-y-4">
                  <h3 className="text-xl font-semibold text-blue-600">For Operations</h3>
                  <ul className="space-y-2">
                    <li className="flex items-center">
                      <ArrowRight className="w-5 h-5 text-blue-500 mr-2" />
                      Increased efficiency
                    </li>
                    <li className="flex items-center">
                      <ArrowRight className="w-5 h-5 text-blue-500 mr-2" />
                      Error reduction
                    </li>
                    <li className="flex items-center">
                      <ArrowRight className="w-5 h-5 text-blue-500 mr-2" />
                      24/7 operation
                    </li>
                  </ul>
                </div>
                <div className="space-y-4">
                  <h3 className="text-xl font-semibold text-blue-600">For Business</h3>
                  <ul className="space-y-2">
                    <li className="flex items-center">
                      <ArrowRight className="w-5 h-5 text-blue-500 mr-2" />
                      Cost savings
                    </li>
                    <li className="flex items-center">
                      <ArrowRight className="w-5 h-5 text-blue-500 mr-2" />
                      Faster processing
                    </li>
                    <li className="flex items-center">
                      <ArrowRight className="w-5 h-5 text-blue-500 mr-2" />
                      Better compliance
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        );

      // Other sections remain unchanged
      case 'Business Case':
        return (
          // Existing Business Case content
          <div className="space-y-6">
            <h2 className="text-3xl font-bold mb-6 text-center">Nova Farms Case Study</h2>
            
            <div className="bg-white p-6 rounded-lg shadow-md">
              <div className="aspect-[8.5/11] w-full">
                <iframe
                  src="https://cdn.prod.website-files.com/6656cc98d077ac69272f468d/6719bb68c0e9ffdafb9ee4ab_Nova_Farms_and_FixFinder.pdf"
                  className="w-full h-full border-0 rounded-lg"
                  title="Nova Farms Case Study"
                />
              </div>
              
              <div className="mt-4 text-center">
                <a
                  href="https://cdn.prod.website-files.com/6656cc98d077ac69272f468d/6719bb68c0e9ffdafb9ee4ab_Nova_Farms_and_FixFinder.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-flex items-center text-blue-600 hover:text-blue-800"
                >
                  <ArrowRight className="w-4 h-4 mr-2" />
                  Open PDF in new tab
                </a>
              </div>
            </div>
          </div>
        );

      case 'Feedback':
        return (
          <div className="space-y-6">
            <h2 className="text-3xl font-bold mb-6 text-center">Share Your Thoughts</h2>
            <FeedbackForm solutionName={name} />
          </div>
        );

      default:
        return <p>Select a section to view details.</p>;
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      {renderContent()}
    </div>
  );
};

export default AutomationService;