import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Slider from 'react-slick';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import 'slick-carousel/slick/slick.css';
import { Loader, ChevronLeft, ChevronRight } from 'lucide-react';
import { figmaApiKey, getFigmaFileKey, getFrameNames } from '../utils/figmaConfig';

interface EnhancedFigmaViewerProps {
  fileLabel: string;
  preloadLabels?: string[];
}

interface FrameData {
  id: string;
  name: string;
  imageUrl: string;
}

const cache: { [key: string]: FrameData[] } = {};

const fetchFigmaImages = async (fileLabel: string): Promise<FrameData[]> => {
  try {
    if (cache[fileLabel]) {
      return cache[fileLabel];
    }

    const fileKey = getFigmaFileKey(fileLabel);
    const specificFrameNames = getFrameNames(fileLabel);

    const fileResponse = await axios.get(`https://api.figma.com/v1/files/${fileKey}`, {
      headers: { 'X-Figma-Token': figmaApiKey }
    });

    let frames = fileResponse.data.document.children[0].children.filter((child: any) =>
      child.type === 'FRAME' &&
      (specificFrameNames.length === 0 || specificFrameNames.includes(child.name))
    );

    if (frames.length === 0) {
      return [];
    }

    frames = frames.reverse();
    const frameIds = frames.map((frame: any) => frame.id);

    const imagePromises = frameIds.map((id: string) =>
      axios.get(`https://api.figma.com/v1/images/${fileKey}?ids=${id}&format=png&scale=2`, {
        headers: { 'X-Figma-Token': figmaApiKey }
      })
    );

    const imageResponses = await Promise.all(imagePromises);

    const newFrameData = frames.map((frame: any, index: number) => ({
      id: frame.id,
      name: frame.name,
      imageUrl: imageResponses[index].data.images[frame.id],
    }));

    cache[fileLabel] = newFrameData;
    return newFrameData;
  } catch (err) {
    console.error('Error fetching Figma data:', err);
    return [];
  }
};

const preloadImage = (url: string) => {
  const img = new Image();
  img.src = url;
};

const SingleImageView: React.FC<{ frame: FrameData }> = ({ frame }) => (
  <div>
    <h3 className="text-xl font-semibold mb-2 text-center">{frame.name}</h3>
    <Zoom>
      <img
        src={frame.imageUrl}
        alt={`Figma Design - ${frame.name}`}
        className="w-full h-auto object-contain rounded-lg"
        style={{ maxHeight: '85vh' }}
      />
    </Zoom>
  </div>
);

const NextArrow: React.FC<{ onClick?: () => void }> = ({ onClick }) => (
  <button
    onClick={onClick}
    className="absolute right-4 top-1/2 transform -translate-y-1/2 z-10 bg-black bg-opacity-50 p-2 rounded-full text-white"
  >
    <ChevronRight size={24} />
  </button>
);

const PrevArrow: React.FC<{ onClick?: () => void }> = ({ onClick }) => (
  <button
    onClick={onClick}
    className="absolute left-4 top-1/2 transform -translate-y-1/2 z-10 bg-black bg-opacity-50 p-2 rounded-full text-white"
  >
    <ChevronLeft size={24} />
  </button>
);

const EnhancedFigmaViewer: React.FC<EnhancedFigmaViewerProps> = ({ fileLabel, preloadLabels = [] }) => {
  const [frameData, setFrameData] = useState<FrameData[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const loadImages = async () => {
      try {
        setLoading(true);
        setError(null);

        const data = await fetchFigmaImages(fileLabel);
        if (data.length === 0) {
          setError('No matching frames found in the Figma file.');
        } else {
          setFrameData(data);
        }
      } catch (err) {
        console.error('Error loading Figma images:', err);
        setError('Failed to load Figma design. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    loadImages();
  }, [fileLabel]);

  useEffect(() => {
    const preloadNextPages = async () => {
      for (const label of preloadLabels) {
        if (!cache[label]) {
          const data = await fetchFigmaImages(label);
          data.forEach(frame => preloadImage(frame.imageUrl));
        }
      }
    };

    if (!loading && frameData.length > 0) {
      preloadNextPages();
    }
  }, [loading, frameData, preloadLabels]);

  if (loading) {
    return (
      <div className="flex flex-col items-center justify-center h-64">
        <Loader className="w-12 h-12 text-blue-500 animate-spin mb-4" />
        <p className="text-lg text-gray-600">Loading high-resolution images...</p>
      </div>
    );
  }

  if (error) return <div className="text-center py-4 text-red-500">{error}</div>;
  if (frameData.length === 0) return <div className="text-center py-4">No images found for this view.</div>;

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <div className="figma-viewer mx-auto">
      {frameData.length === 1 ? (
        <SingleImageView frame={frameData[0]} />
      ) : (
        <Slider {...settings}>
          {frameData.map((frame) => (
            <SingleImageView key={frame.id} frame={frame} />
          ))}
        </Slider>
      )}
    </div>
  );
};

export default EnhancedFigmaViewer;