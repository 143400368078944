export const figmaApiKey = import.meta.env.VITE_FIGMA_API_KEY || '';
export const figmaFiles: { [key: string]: string } = JSON.parse(import.meta.env.VITE_FIGMA_FILES || '{}');

// Add the new file keys
figmaFiles['desktop_troubleshoot'] = 'xKoSVOxJ943zJissJRCZa9';
figmaFiles['desktop_knowledge'] = '2CdIpQjXI9vjSpdp7vXqAY';
figmaFiles['system_status'] = '91QjjAWek6wxiOlj84y8aN';
figmaFiles['desktop_tickets'] = 'eEunWw6eXAjBBnpMiuPskn';
figmaFiles['desktop_library'] = 'D5DQk1x4pHFAzrdiRepRtU';
figmaFiles['desktop_search'] = '3md2ahFxWFSUf1OoJxnNSE';
figmaFiles['onboarding'] = 'th1D4Kd1pGnRF8fPEChUo3';
figmaFiles['dashboard'] = 'Qh4z0OpW0qeiknWs7JMPo7';
figmaFiles['flyer'] = 'SxuJ4Mw2qMvvjn2cQY9u4I';

export const getFigmaFileKey = (label: string): string => {
  return figmaFiles[label] || '';
};

export const getFrameNames = (label: string): string[] => {
  // Implement the logic to return frame names based on the label
  // For now, return an empty array as a placeholder
  console.log(`Getting frame names for label: ${label}`);
  return [];
};