import React, { useState } from 'react';
import { Files, Users, RefreshCw, Brain, ArrowRight, ExternalLink } from 'lucide-react';
import FeedbackForm from '../FeedbackForm';
import VideoModal from '../VideoModal';
import PitchVideoButton from '../PitchVideoButton';

interface WorkflowOrchestratorProps {
  activeSection: string;
  name: string;
}

const WorkflowOrchestrator: React.FC<WorkflowOrchestratorProps> = ({ activeSection, name }) => {
  const [showVideo, setShowVideo] = useState(false);

  const renderContent = () => {
    switch (activeSection) {
      case 'Overview':
        return (
          <div className="space-y-8">
            <header className="text-center mb-12 bg-gradient-to-r from-blue-600 to-blue-800 text-white py-16 rounded-lg">
              <h1 className="text-4xl font-bold mb-4">Workflow Orchestrator</h1>
              <p className="text-xl mb-6">Intelligent workflow management and automation</p>
              <PitchVideoButton onClick={() => setShowVideo(true)} />
            </header>

            <VideoModal 
              isOpen={showVideo}
              onClose={() => setShowVideo(false)}
              title="Workflow Orchestrator Overview"
            />

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
              {[
                {
                  icon: Files,
                  title: 'Workflow Management',
                  description: 'Streamlined processes'
                },
                {
                  icon: Users,
                  title: 'Team Collaboration',
                  description: 'Enhanced teamwork'
                },
                {
                  icon: RefreshCw,
                  title: 'Process Automation',
                  description: 'Automated workflows'
                },
                {
                  icon: Brain,
                  title: 'Intelligent Routing',
                  description: 'Smart task distribution'
                }
              ].map((feature, index) => (
                <div key={index} className="bg-white p-6 rounded-lg shadow-md">
                  <feature.icon className="w-8 h-8 text-blue-600 mb-4" />
                  <h4 className="text-xl font-semibold mb-2">{feature.title}</h4>
                  <p className="text-gray-600">{feature.description}</p>
                </div>
              ))}
            </div>

            <div className="bg-white p-8 rounded-lg shadow-md">
              <h2 className="text-2xl font-semibold mb-6">Platform Benefits</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div className="space-y-4">
                  <h3 className="text-xl font-semibold text-blue-600">For Teams</h3>
                  <ul className="space-y-2">
                    <li className="flex items-center">
                      <ArrowRight className="w-5 h-5 text-blue-500 mr-2" />
                      Improved collaboration
                    </li>
                    <li className="flex items-center">
                      <ArrowRight className="w-5 h-5 text-blue-500 mr-2" />
                      Clear task ownership
                    </li>
                    <li className="flex items-center">
                      <ArrowRight className="w-5 h-5 text-blue-500 mr-2" />
                      Real-time updates
                    </li>
                  </ul>
                </div>
                <div className="space-y-4">
                  <h3 className="text-xl font-semibold text-blue-600">For Business</h3>
                  <ul className="space-y-2">
                    <li className="flex items-center">
                      <ArrowRight className="w-5 h-5 text-blue-500 mr-2" />
                      Increased efficiency
                    </li>
                    <li className="flex items-center">
                      <ArrowRight className="w-5 h-5 text-blue-500 mr-2" />
                      Cost reduction
                    </li>
                    <li className="flex items-center">
                      <ArrowRight className="w-5 h-5 text-blue-500 mr-2" />
                      Better oversight
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        );

      case 'Prototype':
        return (
          <div className="space-y-6">
            <h2 className="text-3xl font-bold mb-6 text-center">{name} Prototype</h2>
            
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-2xl font-semibold mb-4">Prototype Overview</h3>
              <p className="mb-6">
                The Workflow Orchestrator prototype demonstrates our innovative approach to workflow management and process automation, 
                showcasing how teams can streamline their operations and improve collaboration.
              </p>
              <div className="text-center">
                <a 
                  href="https://workflows.fixfinder.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-flex items-center bg-blue-600 text-white px-8 py-3 rounded-full font-semibold hover:bg-blue-700 transition duration-300"
                >
                  <ExternalLink size={20} className="mr-2" />
                  Open Prototype
                </a>
              </div>
            </div>

            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-2xl font-semibold mb-4">Key Features</h3>
              <div className="space-y-4">
                {[
                  'Visual Workflow Builder',
                  'Real-Time Process Monitoring',
                  'Team Collaboration Tools',
                  'Automated Task Distribution',
                  'Performance Analytics Dashboard'
                ].map((feature, index) => (
                  <div key={index} className="p-4 bg-gray-50 rounded-lg">
                    <h4 className="font-semibold mb-2">{feature}</h4>
                    <p>Experience the power of {feature.toLowerCase()} in streamlining your operations.</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        );

      case 'Feedback':
        return (
          <div className="space-y-6">
            <h2 className="text-3xl font-bold mb-6 text-center">Share Your Thoughts</h2>
            <FeedbackForm solutionName={name} />
          </div>
        );

      default:
        return <p>Select a section to view details.</p>;
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      {renderContent()}
    </div>
  );
};

export default WorkflowOrchestrator;