import React, { useState } from 'react';
import { ChevronDown, ThumbsUp, ArrowUp, X } from 'lucide-react';
import { Project, Solution } from '../roadmap/types';
import { desktopSolutions, cloudSolutions, inDevelopment, queuedIntegrations } from '../roadmap/data';
import { getStatusColor, getStatusEmoji } from '../roadmap/utils';

const Roadmap: React.FC = () => {
  const [expandedProject, setExpandedProject] = useState<Project | Solution | null>(null);
  const [queuedItems, setQueuedItems] = useState(queuedIntegrations);
  const [showVoteMessage, setShowVoteMessage] = useState<string | null>(null);

  const handleProjectClick = (project: Project | Solution) => {
    setExpandedProject(project);
  };

  const closeProjectModal = () => {
    setExpandedProject(null);
  };

  const handleVote = (name: string) => {
    setQueuedItems(prev =>
      prev.map(integration =>
        integration.name === name ? { ...integration, votes: integration.votes + 1 } : integration
      )
    );
    setShowVoteMessage(name);
    setTimeout(() => setShowVoteMessage(null), 3000);
  };

  return (
    <div className="p-6 max-w-6xl mx-auto">
      <header className="text-center mb-12 bg-gradient-to-r from-blue-600 to-blue-800 text-white py-16 rounded-lg">
        <h1 className="text-3xl md:text-4xl font-bold mb-4">Our Roadmap – Shaped by Your Feedback</h1>
        <p className="text-lg md:text-xl max-w-3xl mx-auto px-4">
          We take your feedback seriously and group clients into cohorts based on their platform needs and integrations. 
          Our goal is to prioritize features and build them from existing prototypes, evolving alongside your needs.
        </p>
      </header>

      <section className="mb-12">
        <h2 className="text-2xl font-semibold mb-6">Desktop Solutions</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {desktopSolutions.map((project) => (
            <div
              key={project.name}
              className="bg-white border rounded-lg shadow-sm p-4 cursor-pointer hover:shadow-md transition-shadow"
              onClick={() => handleProjectClick(project)}
            >
              <div className="flex justify-between items-center">
                <div>
                  <h3 className="text-xl font-semibold">
                    {getStatusEmoji(project.status)} {project.name}
                  </h3>
                  <span className={`inline-block px-2 py-1 rounded-full text-sm font-semibold mt-2 ${getStatusColor(project.status)}`}>
                    {project.status.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                  </span>
                  <p className="mt-2 text-gray-600 text-sm">{project.description}</p>
                </div>
                <ChevronDown className="text-gray-500" />
              </div>
            </div>
          ))}
        </div>
      </section>

      <section className="mb-12">
        <h2 className="text-2xl font-semibold mb-6">Cloud Solutions</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {cloudSolutions.map((solution) => (
            <div
              key={solution.name}
              className="bg-white border rounded-lg shadow-sm p-4 cursor-pointer hover:shadow-md transition-shadow"
              onClick={() => handleProjectClick(solution)}
            >
              <div className="flex justify-between items-center">
                <div>
                  <h3 className="text-xl font-semibold">
                    {getStatusEmoji(solution.status)} {solution.name}
                  </h3>
                  <span className={`inline-block px-2 py-1 rounded-full text-sm font-semibold mt-2 ${getStatusColor(solution.status)}`}>
                    {solution.status.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                  </span>
                  <p className="mt-2 text-gray-600 text-sm">{solution.description}</p>
                </div>
                <ChevronDown className="text-gray-500" />
              </div>
            </div>
          ))}
        </div>
      </section>

      <section className="mb-12">
        <h2 className="text-2xl font-semibold mb-6">In Development</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {inDevelopment.map((project) => (
            <div
              key={project.name}
              className="bg-white border rounded-lg shadow-sm p-4 cursor-pointer hover:shadow-md transition-shadow"
              onClick={() => handleProjectClick(project)}
            >
              <div className="flex justify-between items-center">
                <div>
                  <h3 className="text-xl font-semibold">
                    {getStatusEmoji(project.status)} {project.name}
                  </h3>
                  <span className={`inline-block px-2 py-1 rounded-full text-sm font-semibold mt-2 ${getStatusColor(project.status)}`}>
                    {project.status.charAt(0).toUpperCase() + project.status.slice(1)}
                  </span>
                  <p className="mt-2 text-gray-600 text-sm">{project.description}</p>
                </div>
                <ChevronDown className="text-gray-500" />
              </div>
            </div>
          ))}
        </div>
      </section>

      <section className="mb-12">
        <h2 className="text-2xl font-semibold mb-6">Queued Integrations</h2>
        <div className="bg-white border rounded-lg shadow-sm overflow-hidden">
          <div className="max-h-96 overflow-y-auto">
            {queuedItems
              .sort((a, b) => b.votes - a.votes)
              .map((integration) => (
                <div key={integration.name} className="flex justify-between items-center p-4 border-b last:border-b-0">
                  <span>{integration.name}</span>
                  <div className="flex items-center">
                    <span className="mr-4">{integration.votes} votes</span>
                    <button
                      onClick={() => handleVote(integration.name)}
                      className="bg-blue-500 text-white p-2 rounded-full hover:bg-blue-600 transition-colors"
                    >
                      <ThumbsUp size={16} />
                    </button>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </section>

      {expandedProject && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-lg w-full relative">
            <button
              onClick={closeProjectModal}
              className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
            >
              <X size={24} />
            </button>
            <h3 className="text-2xl font-semibold mb-4">
              {getStatusEmoji(expandedProject.status)} {expandedProject.name}
            </h3>
            <span className={`inline-block px-2 py-1 rounded-full text-sm font-semibold ${getStatusColor(expandedProject.status)}`}>
              {expandedProject.status.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
            </span>
            <p className="mt-4">{expandedProject.description}</p>
          </div>
        </div>
      )}

      {showVoteMessage && (
        <div className="fixed bottom-4 right-4 bg-green-500 text-white px-4 py-2 rounded shadow-lg animate-fade-in">
          Thank you! Your vote helps us prioritize integrations.
        </div>
      )}

      <button 
        onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
        className="fixed bottom-4 right-4 bg-blue-500 text-white p-3 rounded-full shadow-lg hover:bg-blue-600 transition-colors"
      >
        <ArrowUp size={24} />
      </button>
    </div>
  );
};

export default Roadmap;