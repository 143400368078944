import React, { useState } from 'react';
import { Target, GitMerge, Compass, Brain, ArrowRight, X, Play, ExternalLink } from 'lucide-react';
import FeedbackForm from '../FeedbackForm';

interface ProjectPathProps {
  activeSection: string;
  name: string;
}

const ProjectPath: React.FC<ProjectPathProps> = ({ activeSection, name }) => {
  const [showVideo, setShowVideo] = useState(false);

  const renderContent = () => {
    switch (activeSection) {
      case 'Overview':
        return (
          <div className="space-y-8">
            <header className="text-center mb-12 bg-gradient-to-r from-blue-600 to-blue-800 text-white py-16 rounded-lg">
              <h1 className="text-4xl font-bold mb-4">Project Path</h1>
              <p className="text-xl mb-6">Expert guidance for complex IT migrations and implementations</p>
              <button 
                onClick={() => setShowVideo(true)}
                className="bg-white text-blue-600 px-6 py-2 rounded-full font-semibold hover:bg-blue-100 transition duration-300 flex items-center mx-auto"
              >
                <Play size={20} className="mr-2" />
                Pitch Video
              </button>
            </header>

            {showVideo && (
              <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50 p-4">
                <div className="relative w-full max-w-4xl bg-black rounded-lg overflow-hidden">
                  <button
                    onClick={() => setShowVideo(false)}
                    className="absolute top-4 right-4 text-white hover:text-gray-300 z-10"
                  >
                    <X size={24} />
                  </button>
                  <div className="aspect-video bg-gray-800 flex items-center justify-center">
                    <div className="text-center text-white">
                      <Play size={48} className="mx-auto mb-4 opacity-50" />
                      <p className="text-xl">Video Coming Soon</p>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
              {[
                {
                  icon: GitMerge,
                  title: 'Migration Expertise',
                  description: 'Specialized guidance for platform transitions'
                },
                {
                  icon: Compass,
                  title: 'Smart Navigation',
                  description: 'Interactive decision trees'
                },
                {
                  icon: Brain,
                  title: 'AI-Powered Insights',
                  description: 'Intelligent recommendations'
                },
                {
                  icon: Target,
                  title: 'Precision Planning',
                  description: 'Detailed migration playbooks'
                }
              ].map((feature, index) => (
                <div key={index} className="bg-white p-6 rounded-lg shadow-md">
                  <feature.icon className="w-8 h-8 text-blue-600 mb-4" />
                  <h4 className="text-xl font-semibold mb-2">{feature.title}</h4>
                  <p className="text-gray-600">{feature.description}</p>
                </div>
              ))}
            </div>

            <div className="bg-white p-8 rounded-lg shadow-md">
              <h2 className="text-2xl font-semibold mb-6">Migration Capabilities</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div className="space-y-4">
                  <h3 className="text-xl font-semibold text-blue-600">Platform Migrations</h3>
                  <ul className="space-y-2">
                    <li className="flex items-center">
                      <ArrowRight className="w-5 h-5 text-blue-500 mr-2" />
                      Google Workspace to Microsoft 365
                    </li>
                    <li className="flex items-center">
                      <ArrowRight className="w-5 h-5 text-blue-500 mr-2" />
                      Email security platform transitions
                    </li>
                    <li className="flex items-center">
                      <ArrowRight className="w-5 h-5 text-blue-500 mr-2" />
                      Identity management migrations
                    </li>
                  </ul>
                </div>
                <div className="space-y-4">
                  <h3 className="text-xl font-semibold text-blue-600">Tool Integration</h3>
                  <ul className="space-y-2">
                    <li className="flex items-center">
                      <ArrowRight className="w-5 h-5 text-blue-500 mr-2" />
                      ProofPoint implementation
                    </li>
                    <li className="flex items-center">
                      <ArrowRight className="w-5 h-5 text-blue-500 mr-2" />
                      Mimecast configuration
                    </li>
                    <li className="flex items-center">
                      <ArrowRight className="w-5 h-5 text-blue-500 mr-2" />
                      Security tool deployment
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="bg-white p-8 rounded-lg shadow-md">
              <h2 className="text-2xl font-semibold mb-6">Example: Google to Microsoft Migration</h2>
              <div className="space-y-6">
                {[
                  {
                    title: 'Discovery & Planning',
                    description: 'Interactive questionnaires and customized checklists based on your environment'
                  },
                  {
                    title: 'Tool Integration',
                    description: 'Automated setup with Migration Wiz and PowerShell script recommendations'
                  },
                  {
                    title: 'Execution Guidance',
                    description: 'Step-by-step instructions for account setup, data migration, and OneDrive deployment'
                  }
                ].map((step, index) => (
                  <div key={index} className="flex items-start p-4 bg-gray-50 rounded-lg">
                    <ArrowRight className="w-6 h-6 text-blue-500 mr-4 mt-1" />
                    <div>
                      <h3 className="font-semibold mb-2">{step.title}</h3>
                      <p className="text-gray-600">{step.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        );

      case 'Prototype':
        return (
          <div className="space-y-6">
            <h2 className="text-3xl font-bold mb-6 text-center">Project Path Prototype</h2>
            
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-2xl font-semibold mb-4">Prototype Overview</h3>
              <p className="mb-6">
                The Project Path prototype demonstrates our innovative approach to managing complex IT migrations and implementations,
                showcasing how teams can efficiently plan, execute, and track migration projects.
              </p>
              <div className="text-center">
                <a 
                  href="https://projectpath.fixfinder.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-flex items-center bg-blue-600 text-white px-8 py-3 rounded-full font-semibold hover:bg-blue-700 transition duration-300"
                >
                  <ExternalLink size={20} className="mr-2" />
                  Open Prototype
                </a>
              </div>
            </div>

            <div className="bg-white p-6 rounded-lg shadow-md mt-8">
              <h3 className="text-2xl font-semibold mb-4">Key Features</h3>
              <div className="space-y-4">
                {[
                  'Interactive Migration Planning',
                  'Real-Time Progress Tracking',
                  'Resource Management Tools',
                  'Automated Task Distribution',
                  'Risk Assessment Dashboard'
                ].map((feature, index) => (
                  <div key={index} className="p-4 bg-gray-50 rounded-lg">
                    <h4 className="font-semibold mb-2">{feature}</h4>
                    <p>Experience the power of {feature.toLowerCase()} in streamlining your migrations.</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        );

      case 'Feedback':
        return (
          <div className="space-y-6">
            <h2 className="text-3xl font-bold mb-6 text-center">Share Your Thoughts</h2>
            <FeedbackForm solutionName={name} />
          </div>
        );

      default:
        return <p>Select a section to view details.</p>;
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      {renderContent()}
    </div>
  );
};

export default ProjectPath;